import moment from 'moment-timezone';

import { Org, Transaction, TransactionLite } from '@/api-svc-types';

export const createTxnRequest = (data: any) => {
  const { transactionType, wallet, transactionDate, transactionTime } = data;
  const { type } = transactionType;

  if (!transactionDate) {
    return null;
  }

  const validTransactionTime = transactionTime || '00:00:00';

  const createdSEC = moment(`${transactionDate} ${validTransactionTime}`).unix();

  const commonFields = {
    type,
    walletId: wallet.id,
    walletName: wallet.name,
    createdSEC,
    source: 'custom',
    isComplete: true,
  };

  if (type === 'manual') {
    const { manual } = data;
    const transaction = generateManualTxnRequest(manual);
    return { ManualTransaction: { ...commonFields, ...transaction } };
  }
  if (type === 'trade') {
    const { trade } = data;
    const transaction = generateTradeTxnResquest(trade);
    return { TradeTransaction: { ...commonFields, ...transaction } };
  }

  return null;
};

const generateManualTxnRequest = (data: any) => {
  const { coin, transactionType, amount, addressFrom, addressTo } = data;

  const { type } = transactionType;

  const coinTicker = String(coin);

  const from = [
    {
      address: addressFrom,
      amount: amount,
      ticker: coinTicker,
    },
  ];
  const to = [
    {
      address: addressTo,
      amount: amount,
      ticker: coinTicker,
    },
  ];
  const amounts = [
    {
      amount: amount,
      ticker: coinTicker,
    },
  ];

  const request = {
    from,
    to,
    transactionType: type,
    amounts,
  };
  return request;
};

const generateTradeTxnResquest = (data: any) => {
  const { transactionType, acquiredAmount, acquiredCoin, disposedCoin, disposedAmount, feesAmount, feesCoin } = data;

  const { type } = transactionType;

  const acquiredCoinSelected = String(acquiredCoin);

  const disposedCoinSelected = String(disposedCoin);

  const feesCoinSelected = String(feesCoin);

  const acquiredAssets = acquiredCoin
    ? [
        {
          amount: acquiredAmount,
          ticker: acquiredCoinSelected,
        },
      ]
    : [];

  const disposedAssets = disposedCoin
    ? [
        {
          amount: disposedAmount,
          ticker: disposedCoinSelected,
        },
      ]
    : [];

  const fees = feesCoin
    ? [
        {
          amount: feesAmount,
          ticker: feesCoinSelected,
        },
      ]
    : [];

  return {
    tradeType: type,
    acquiredAssets,
    disposedAssets,
    fees,
  };
};

export function isTxnClosed(txn: TransactionLite | Transaction, org: Org): boolean {
  if (!org || !org.timezone) return false;
  const txnDate = txn.created;
  if (txnDate == null) return false;
  const hardCloseDate = org.hardCloseDate;
  if (hardCloseDate == null) return false;

  const txnDateMoment = moment.unix(txnDate);
  const hardCloseDateMoment = moment(hardCloseDate).tz(org.timezone, true).endOf('day');
  return txnDateMoment.isSameOrBefore(hardCloseDateMoment);
}
