<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-autocomplete
        :items="filteredCategories"
        :label="accountLabel"
        v-model="category"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        :disabled="readonly"
        v-on:change="updateTransactionData"
      >
        <template slot="item" slot-scope="data">
          <template>
            <v-list-tile-content
              >{{ data.item.name }} <sub>{{ data.item.type }}</sub></v-list-tile-content
            >
          </template>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-flex xs12 v-if="feePresent">
      <v-autocomplete
        v-model="feeContact"
        :items="contacts"
        :label="$tc('_feeContact', 1)"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        v-on:change="updateTransactionData"
      >
      </v-autocomplete>
    </v-flex>
    <v-flex xs2 v-for="(subMetadata, index) in metadata" :key="subMetadata.type">
      <v-flex xs10>
        <v-autocomplete
          :disabled="readonly"
          :items="subMetadata.metadata"
          :label="subMetadata.type"
          v-model="txnMetadata[index]"
          item-text="name"
          item-value="id"
          persistent-hint
          v-on:change="updateTransactionData"
        >
          <template slot="item" slot-scope="data">
            <template>
              <v-list-tile-content>
                {{ data.item.name }}
              </v-list-tile-content>
            </template>
          </template>
        </v-autocomplete>
      </v-flex>
    </v-flex>
    <v-flex xs12>
      <v-textarea
        :label="$t('_description')"
        solo
        auto-grow
        rows="3"
        v-model="description"
        :disabled="readonly"
        v-on:input="updateTransactionData"
      ></v-textarea>
    </v-flex>
    <v-flex xs12>
      <cost-basis
        ref="cbComponent"
        :readonly="readonly"
        :txn-type="txnType"
        v-model="costBasis"
        v-on:input="updateTransactionData"
        :txn="txn"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import gql from 'graphql-tag';
import _ from 'lodash';
import * as math from 'mathjs';

import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly', 'accountingConnectionId'],
  data() {
    return {
      costBasis: null,
      description: '',
      category: '',
      feeContact: null,
      txnMetadata: [],
    };
  },
  apollo: {
    metadata: {
      query: gql`
        query getMetadata($orgId: ID!, $connectionId: ID, $includeDisabled: Boolean) {
          metadata(orgId: $orgId, connectionId: $connectionId, includeDisabled: $includeDisabled) {
            id
            enabled
            source
            metaType
            name
            remoteType
            connectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.accountingConnectionId,
          includeDisabled: false,
        };
      },
      update(data) {
        if (!data.metadata) return [];
        const metadataObj = {};
        data.metadata.forEach((m) => {
          if (!m) return;
          if (!metadataObj[m.remoteType]) metadataObj[m.remoteType] = [];
          metadataObj[m.remoteType].push(m);
        });
        const metadataByType = Object.entries(metadataObj).map(([type, metadata]) => {
          return {
            type,
            metadata,
          };
        });
        return metadataByType;
      },
    },
  },
  mounted() {
    this.populateForm();
    this.updateTransactionData();
  },
  methods: {
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.accountTransfer) {
          this.description = ad.accountTransfer.description;
          const foundCategory = this.categories.find((m) => m.id === ad.accountTransfer.otherAccountId);
          this.category = foundCategory;

          const feeContact = this.contacts.find((m) => m.id === ad.accountTransfer.feeContactId);
          this.feeContact = feeContact;
          this.txnMetadata = ad.accountTransfer.metadataIds;
        } else {
          // throw new Error("Bad accounting details");
        }
      }
    },
    updateTransactionData() {
      /*
        input AccountTransferTransactionInput {
            description: String!
            otherAccountId: ID!
            notionalTransferValue: UncheckedValueBase!
            notionalFeeValue: UncheckedValueBase
            exchangeRates: [ExchangeRateInput]
            metadata: [String]
          }
       */
      const transactionData = {};
      transactionData.valid = true;
      const errors = [];

      if (this.txn.amounts.length !== 1) {
        errors.push('Incorrect number of amounts for transfer');
      }

      const amntObj = this.txn.amounts[0];

      const amountVal = math.bignumber(amntObj.value);

      let direction;
      if (amountVal.lt(0)) {
        direction = 'Outgoing';
      } else if (amountVal.gt(0)) {
        direction = 'Incoming';
      } else {
        transactionData.valid = false;
        errors.push('Unable to handle zero value transactions for transfers');
      }

      let feeObj;
      let feeContactId;
      if (this.txn.paidFees) {
        if (this.txn.paidFees.length > 1) {
          errors.push('Too many fees from server');
        } else if (this.txn.paidFees.length === 1) {
          // feeObj = this.txn.paidFees[0];
          feeObj = {
            value: this.txn.paidFees[0].value,
            coin: this.txn.paidFees[0].coin,
            unit: this.txn.paidFees[0].unit,
          };

          if (!this.feeContact) {
            transactionData.valid = false;
            errors.push('Fee Contact must be defined');
          }
          feeContactId = this.feeContact?.id;
        }
      }

      // Check if the amount and fee are in the base currency.
      // If not, need to calculate notional values from costBasis obj
      let notionalTransferValue;
      let exchangeRatesRequired = false;
      // const baseCurrency = getCurrencyFromEnum(
      //   this.$store.state.currentOrg.baseCurrency
      // );
      const baseCurrency = this.$store.state.currentOrg.baseCurrency;
      if (amntObj.coin === baseCurrency) {
        /// basecurrency is int, amnt
        const val = math.bignumber(amntObj.value).abs().toString();
        notionalTransferValue = {
          value: val,
          coin: amntObj.coin,
          unit: amntObj.unit,
        };
      } else {
        exchangeRatesRequired = true;
        // something fomr cost basis
        // notionalTransferAmount = this.costBasis

        const er = this.costBasis?.exchangeRates.find((m) => m.coin === amntObj.coin);
        if (er) {
          notionalTransferValue = {
            value: math.bignumber(amntObj.value).mul(er.rate).abs().toString(),
            coin: this.costBasis.currency,
          };
        } else {
          transactionData.valid = false;
          errors.push('Unable to find fee exchange rate');
        }

        // notionalTransferValue = {
        //   value: this.costBasis.cost,
        //   coin: this.costBasis.currency
        // };
      }

      let notionalFeeValue;
      if (feeObj && feeObj.coin === baseCurrency) {
        notionalFeeValue = feeObj;
      } else if (feeObj) {
        exchangeRatesRequired = true;
        const er = this.costBasis?.exchangeRates.find((m) => m.coin === feeObj.coin);
        if (er) {
          notionalFeeValue = {
            value: math.bignumber(feeObj.value).mul(er.rate).abs().toString(),
            coin: this.costBasis.currency,
          };
        } else {
          transactionData.valid = false;
          errors.push('Unable to find fee exchange rate');
        }

        // something to get fee notional value
      }

      // const transferValue = math.bignumber(amnt.value);

      // if transfer amount is in base currency, grab

      if (!this.category || !this.category.id) {
        transactionData.valid = false;
        errors.push('Please select a valid transaction category.');
      }

      transactionData.accountTransfer = {
        otherAccountId: this.category.id,
        description: this.description,
        notionalTransferValue,
        notionalFeeValue,
        feeContactId,
        direction,
        metadata: this.txnMetadata,
      };

      if (exchangeRatesRequired) {
        const exchangeRates = [];
        for (const er of this.$refs.cbComponent.exchangeRates) {
          exchangeRates.push({
            coin: er.coin,
            unit: er.unit,
            fiat: er.fiat,
            rate: er.rate,
            source: er.source,
          });
        }

        transactionData.accountTransfer.exchangeRates = exchangeRates;
      }

      console.log(transactionData);
      this.$emit('input', transactionData);
    },
  },
  computed: {
    filteredCategories() {
      const filtered = _.filter(this.categories, (m) => ['Asset', 'Bank', 'Liability'].includes(m.type));
      return filtered;

      // if (this.txnType === "receive") {
      //   const filtered = _.filter(this.categories, m =>
      //     ["Revenue", "Equity", "Liability", "Asset"].includes(m.type)
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // } else {
      //   return _.filter(
      //     this.categories,
      //     m =>
      //       m.type === "Expense" ||
      //       m.type === "Asset" ||
      //       m.type === "Liability" ||
      //       m.type === "Equity"
      //   );
      // }
    },
    accountLabel() {
      if (this.txn.amounts[0].value < 0) {
        return 'Transfer To...';
      } else {
        return 'Transfer From...';
      }
    },
    feePresent() {
      if (this.txn.paidFees) {
        return this.txn.paidFees.length > 0;
      } else {
        return false;
      }
    },
  },
};
</script>
