import gql from 'graphql-tag';

export const TransactionsPageQuery = gql`
  query GetTransactions($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactions(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      txns {
        id
        created
        description
        networkId
        errored
        errors
        isBlockchain
        ignore
        memo
        type
        reconciliationStatus
        categorizationStatus
        isCombined
        isCombinedSubTransaction
        readonly
        methods
        categorizationSummary
        wallet {
          id
          name
        }
        amounts {
          value
          unit
          displayValue
          coin
        }
        amountsIncludingFees {
          value
          unit
          displayValue
          coin
        }
        fullAmountSet {
          value
          unit
          displayValue
          coin
        }
        hasMatchedInvoices
        viewLinks {
          link
          name
        }
        from {
          address
          amount {
            unit
            coin
            displayValue
          }
        }
        to {
          address
          amount {
            unit
            coin
            displayValue
          }
        }
        txnLogs {
          from {
            address
          }
          to {
            address
          }
          displayAmount
          asset {
            type
            address
            symbol
          }
        }
        fees {
          coin
          value
        }
      }
    }
  }
`;

export const TransactionsTaxQuery = gql`
  query GetTransactions($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactions(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      txns {
        id
        created
        description
        networkId
        ignore
        errored
        errors
        memo
        type
        reconciliationStatus
        categorizationStatus
        isCombined
        isCombinedSubTransaction
        taxReconciliationStatus
        exchangeRates {
          coin
          coinUnit
          fiat
          rate
        }
        wallet {
          id
          name
        }
        amounts {
          value
          unit
          displayValue
          coin
        }
        amountsIncludingFees {
          value
          unit
          displayValue
          coin
        }
        taxInfo {
          ticker
          wallets
          amount
          cost
          saleValue
          exchangeRate
          gainLoss
          isGainLossComplete
        }
        viewLinks {
          link
          name
        }
      }
    }
  }
`;

export const ErroredTransactionsQuery = gql`
  query GetTransactions($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactions(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      txns {
        id
      }
    }
  }
`;

export const TransactionDetailQuery = gql`
  query GetTransaction($orgId: ID!, $transactionId: ID!) {
    transaction(orgId: $orgId, transactionId: $transactionId) {
      id
      created
      description
      editEtag
      ignore
      networkId
      errored
      errors
      memo
      type
      reconciliationStatus
      categorizationStatus
      isCombined
      isCombinedSubTransaction
      readonly
      to {
        address
      }
      from {
        address
      }
      txnLogs {
        type
        from {
          networkId
          address
        }
        to {
          networkId
          address
        }
        amount
        asset {
          type
          networkId
          address
          symbol
        }
      }
      blocks
      exchangeRates {
        coin
        coinUnit
        fiat
        rate
        priceId
      }
      amounts {
        value
        unit
        displayValue
        coin
        fiatValue {
          value
          displayValue
          exchangeRate {
            rate
            dateTime
          }
          currency
        }
      }
      amountsIncludingFees {
        value
        unit
        displayValue
        coin
      }
      fullAmountSet {
        value
        unit
        displayValue
        coin
        fiatValue {
          value
          displayValue
          exchangeRate {
            rate
            dateTime
          }
          currency
        }
      }
      fullAmountSetWithoutFees {
        value
        unit
        displayValue
        coin
        fiatValue {
          value
          displayValue
          exchangeRate {
            rate
            dateTime
          }
          currency
        }
      }
      paidFees {
        value
        unit
        displayValue
        coin
        fiatValue {
          value
          displayValue
          exchangeRate {
            rate
            dateTime
          }
          currency
        }
      }
      wallet {
        id
        name
        addresses
        address
      }
      details {
        to
        from
        amounts {
          coin
          unit
          value
        }
      }
      matchedInvoices {
        id
        type
        title
        date
        dueDate
        totalAmount
        currency
      }
      viewLinks {
        link
        name
      }
      accountingConnectionId
    }
  }
`;

export const WalletsQuery = gql`
  query GetWallets($orgId: ID!) {
    wallets(orgId: $orgId) {
      id
      name
      addresses
      networkId
      type
      defaultInflowContact
      defaultOutflowContact
      defaultInflowCategory
      defaultOutflowCategory
      defaultFeeContact
      defaultFeeCategory
    }
  }
`;

export const ContactsQuery = gql`
  query GetContacts($orgId: ID!) {
    contacts(orgId: $orgId) {
      id
      name
      type
      accountingConnectionId
      addresses {
        coin
        address
      }
    }
  }
`;

export const CategoriesQuery = gql`
  query GetCategories($orgId: ID!) {
    categories(orgId: $orgId) {
      id
      name
      code
      type
      accountingConnectionId
    }
  }
`;

export const BillsQuery = gql`
  query GetBills($orgId: ID!) {
    bills(orgId: $orgId) {
      id
      dueDate
      amount
      displayAmount
      vendor {
        name
        addresses {
          coin
          address
        }
      }
      category {
        name
      }
    }
  }
`;

export const InvoicesQuery = gql`
  query GetInvoices($orgId: ID!) {
    invoices(orgId: $orgId) {
      id
      title
      type
      status
      reference
      date
      dueDate
      dueAmount
      totalAmount
      contactId
      currency
      exchangeRate
      lastUpdatedSEC
    }
  }
`;

export const FilteredTransactionsQuery = gql`
  query GetTransactions($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactions(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      txns {
        id
        created
        description
        networkId
        errored
        errors
        reconciliationStatus
        categorizationStatus
        isCombined
        isCombinedSubTransaction
        accountingDetails {
          sell {
            dateTimeSec
            source
            sourceAmount
            target
            targetAmount
            exchangeRate
            proceedsAccount
            fee
            feeContact
          }
          simple {
            contactId
            contact {
              name
            }
            categoryId
            category {
              name
            }
            description
            costBasis {
              cost
              exchangeRate
              costBasisType
              notes
              invoiceId
            }
          }
          multivalue {
            notes
            items {
              contactId
              transactionType
              lines {
                categoryId
                description
                coin
                unit
                coinAmount
                fiat
                fiatAmount
                sourceValue {
                  type
                  amount
                  ticker
                }
                forceZeroGainLoss
              }
            }
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
          }
          staking {
            contactId
            categoryId
            contactId
            contact {
              name
            }
            categoryId
            category {
              name
            }
            description
            costBasis {
              cost
              exchangeRate
              costBasisType
              notes
              invoiceId
            }
            stakingTxnType
          }
          assetStaking {
            type
            stakedAssets {
              symbol
              value
            }
            totalHoldings {
              symbol
              value
            }
            poolDetails {
              addressPoolTokens
              totalPoolTokens
              percentOfPool
            }
            block {
              networkId
              blockId
            }
            holdingAddress {
              networkId
              address
            }
            stakingTarget {
              networkId
              address
            }
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
            recognizedRevenue {
              contactId
              transactionType
              lines {
                categoryId
                description
                coin
                unit
                coinAmount
                fiat
                fiatAmount
                sourceValue {
                  type
                  amount
                  ticker
                }
              }
            }
            fee {
              feeItems {
                amount {
                  value
                  symbol
                }
                costBasis {
                  cost
                  exchangeRate
                  costBasisType
                  notes
                  invoiceId
                }
              }
              feeContactId
            }
          }
          deFiTransfer {
            fromTotalHoldings {
              symbol
              value
            }
            toTotalHoldings {
              symbol
              value
            }
            poolDetails {
              addressPoolTokens
              totalPoolTokens
              percentOfPool
            }
            block {
              networkId
              blockId
            }
            stakingTarget {
              networkId
              address
            }
            fromAddress {
              networkId
              address
            }
            toAddress {
              networkId
              address
            }
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
            fee {
              feeItems {
                amount {
                  value
                  symbol
                }
                costBasis {
                  cost
                  exchangeRate
                  costBasisType
                  notes
                  invoiceId
                }
              }
              feeContactId
            }
          }
          invoice {
            invoices {
              invoiceId
              ticker
              amount
              contactId
              forex {
                fiat
                categoryId
                amount
              }
              coin
              unit
              coinAmount
            }
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
            fees {
              fee {
                displayValue
                coin
                unit
              }
              cost {
                cost
                exchangeRate
                currency
              }
              feePayeeId
            }
          }
          billPay {
            billId
            contactId
            categoryId
            description
            costBasis {
              cost
              exchangeRate
              costBasisType
              notes
              invoiceId
            }
          }
          transfer {
            notes
            feeContactId
            feeCostBasis {
              cost
              exchangeRate
              costBasisType
              notes
              currency
              invoiceId
            }
          }
          accountTransfer {
            description
            otherAccountId
          }
          trade {
            acquiredAssets {
              asset {
                displayValue
                coin
                unit
                value
                fiatValue {
                  value
                  displayValue
                  exchangeRate {
                    rate
                    dateTime
                  }
                  currency
                }
              }
              fairMarketValue {
                cost
                exchangeRate
                currency
              }
            }
            disposedAssets {
              asset {
                displayValue
                coin
                unit
                value
                fiatValue {
                  value
                  displayValue
                  exchangeRate {
                    rate
                    dateTime
                  }
                  currency
                }
              }
              fairMarketValue {
                cost
                exchangeRate
                currency
              }
            }
            tradeFee {
              assets {
                asset {
                  displayValue
                  coin
                  unit
                }
                fairMarketValue {
                  cost
                  exchangeRate
                  currency
                }
              }
              feeContactId
            }
            systemCreated
            carryForwardCostBasis
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
          }
          simpleTrade {
            acquired {
              displayValue
              coin
              unit
              value
              fiatValue {
                value
                displayValue
                exchangeRate {
                  rate
                  dateTime
                }
                currency
              }
            }
            disposed {
              displayValue
              coin
              unit
              value
              fiatValue {
                value
                displayValue
                exchangeRate {
                  rate
                  dateTime
                }
                currency
              }
            }
            acquiredCost {
              cost
              exchangeRate
              currency
            }
            disposedCost {
              cost
              exchangeRate
              currency
            }
            fee {
              fee {
                displayValue
                coin
                unit
              }
              cost {
                cost
                exchangeRate
                currency
              }
              feePayeeId
            }
            systemCreated
          }
        }
        amounts {
          value
          unit
          displayValue
          coin
          unit
          fiatValue {
            value
            displayValue
            exchangeRate {
              rate
              dateTime
            }
            currency
          }
        }
        fullAmountSet {
          value
          unit
          displayValue
          coin
          fiatValue {
            value
            displayValue
            exchangeRate {
              rate
              dateTime
            }
            currency
          }
        }
        details {
          to
          from
          amounts {
            coin
            unit
            value
          }
        }
        wallet {
          name
        }
        viewLinks {
          link
          name
        }
      }
    }
  }
`;

export const ExternalCostBasisQuery = gql`
  query GetTransactions($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactions(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      txns {
        id
        created
        networkId
        description
        errored
        errors
        reconciliationStatus
        categorizationStatus
        isCombined
        isCombinedSubTransaction
        accountingDetails {
          accountTransfer {
            description
            otherAccountId
            feeContactId
            direction
            dispositionDetails {
              dispositionProceedsInBaseCurrency
              dispositionDate
            }
            acquisitionDetails {
              acquisitionCostInBaseCurrency
              acquisitionDate
            }
            exchangeRates {
              coin
              coinUnit
              fiat
              rate
            }
          }
        }
        amounts {
          value
          unit
          displayValue
          coin
          unit
          fiatValue {
            value
            displayValue
            exchangeRate {
              rate
              dateTime
            }
            currency
          }
        }
        details {
          to
          from
          amounts {
            coin
            unit
            value
          }
        }
        wallet {
          name
        }
        viewLinks {
          link
          name
        }
      }
    }
  }
`;

export const TransactionsLiteQuery = gql`
  query GetTransactionsLite($orgId: ID!, $transactionFilter: TransactionFilter, $limit: Int, $paginationToken: String) {
    transactionsLite(
      orgId: $orgId
      transactionFilter: $transactionFilter
      limit: $limit
      paginationToken: $paginationToken
    ) {
      olderPageToken
      newerPageToken
      coins {
        currencyId
        ticker
        unit
      }
      txns {
        id
        created
        type
        fullAmountSet {
          value
          currencyId
          unit
        }
        fullAmountSetWithoutFees {
          value
          currencyId
          unit
        }
        paidFees {
          value
          currencyId
          unit
        }
        txnLines {
          amount
          assetId
          from
          id
          to
          operation
          subId
          txnLineId
          walletId
          feeAmount
          feeAssetId
        }
        categorizationStatus
        reconciliationStatus
        categorizationSummary
        exchangeRates {
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
            priceId
          }
          exchangeRatesDirty
          exchangeRatesError
        }
        walletAmounts {
          walletId
          amounts {
            value
            currencyId
            unit
          }
        }
        ignore
        memo
        from {
          address
          amount {
            value
            currencyId
          }
        }
        to {
          address
          amount {
            value
            currencyId
          }
        }
        hasMatchedInvoices
        readonly
        viewLinks {
          link
          name
        }
        isCombined
        isCombinedSubTransaction
        errored
        methods
        accountingConnectionId
        metadata
      }
    }
  }
`;

export const TransactionCountQuery = gql`
  query GetTransactionCounts($orgId: ID!, $walletId: ID, $ignore: IgnoreFilter, $pivotDate: String, $walletIds: [ID]) {
    transactionCounts(
      orgId: $orgId
      walletId: $walletId
      ignoreFilter: $ignore
      pivotDate: $pivotDate
      walletIds: $walletIds
    ) {
      all
      uncategorized
      unreconciled
    }
  }
`;
