<template>
  <v-container grid-list-xl text-xs-center>
    <v-layout row wrap>
      <v-card ma-1 pa-1 v-for="(item, index) in splits" :key="index" elevation-2>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs4>
              <v-autocomplete
                v-model="item.contact"
                :disabled="readonly"
                :items="filteredContacts"
                :label="$tc('_contact', 1)"
                item-text="name"
                item-value="id"
                persistent-hint
                return-object
                v-on:change="updateTransactionData"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs4>
              <a @click="deleteSplit(item)"><v-icon>delete</v-icon> Delete Split</a>
              <a v-if="!readonly" @click="deleteSplit(item)"><v-icon>delete</v-icon> Delete Split</a>
            </v-flex>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs12 v-for="(line, line_index) in item.lines" :key="line_index">
                  <v-layout row wrap :color="line.valid === false ? 'error' : ''">
                    <v-flex xs3>
                      <v-autocomplete
                        :disabled="readonly"
                        :items="filteredCategories"
                        :label="$tc('_category', 1) + '*'"
                        v-model="line.category"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        return-object
                        v-on:change="updateTransactionData"
                      >
                        <template slot="item" slot-scope="data">
                          <template>
                            <v-list-tile-content
                              >{{ data.item.name }} <sub>{{ data.item.type }}</sub></v-list-tile-content
                            >
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-flex>

                    <v-flex xs4>
                      <v-text-field
                        v-model="line.description"
                        :label="$tc('_description')"
                        v-on:change="updateTransactionData"
                      ></v-text-field>
                      <v-text-field
                        :disabled="readonly"
                        v-model="line.description"
                        :label="$tc('_description')"
                        v-on:change="updateTransactionData"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field v-model="line.amount" v-on:change="updateTransactionData"></v-text-field>
                      <v-text-field
                        :disabled="readonly"
                        v-model="line.amount"
                        v-on:change="updateTransactionData"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-text-field :label="$tc('_usdValue')" :value="calculateValueInFiat(line.amount)" readonly>
                        v-on:change="updateTransactionData"</v-text-field
                      >
                      <v-text-field
                        :label="$tc('_usdValue')"
                        :disabled="readonly"
                        :value="calculateValueInFiat(line.amount)"
                        readonly
                      >
                        v-on:change="updateTransactionData"</v-text-field
                      >
                    </v-flex>
                    <v-flex xs1>
                      <v-icon @click="deleteLine(item, line, line_index)">delete</v-icon>
                      <v-icon v-if="!readonly" @click="deleteLine(item, line, line_index)">delete</v-icon>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs2 offset-xs10><a @click="addLine(item)">Add Line +</a></v-flex>
                <v-flex xs2 offset-xs10><a v-if="!readonly" @click="addLine(item)">Add Line +</a></v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
        </v-flex>
      </v-card>
      <v-spacer></v-spacer>
      <v-flex xs2><a @click="addSplit">Add Split +</a></v-flex>
      <v-flex xs2><a @click="addSplit" v-if="!readonly">Add Split +</a></v-flex>
      <v-flex xs12>
        <v-layout row wrap>
          <v-flex xs3>
            <b>Total</b>
          </v-flex>
          <v-flex xs4> Expected: {{ txnAmountTotal.toString() }} </v-flex>
          <v-flex xs2 :class="sumValid ? 'success--text' : 'error--text'">
            {{ amountTotal.toNumber() }}
          </v-flex>
          <v-flex xs2 :class="sumValid ? 'success--text' : 'error--text'"> {{ fiatTotal }}</v-flex>
          <v-flex>
            <v-icon color="success" v-if="sumValid">check</v-icon>
            <v-icon color="error" v-else>clear</v-icon>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <cost-basis
          ref="cbComponent"
          :amounts="txn.fullAmountSet"
          :txn-type="txnType"
          v-model="costBasis"
          v-on:input="updateTransactionData"
          :txn="txn"
          :show-cost-basis="false"
        ></cost-basis>
        <cost-basis
          :readonly="readonly"
          ref="cbComponent"
          :amounts="txn.fullAmountSet"
          :txn-type="txnType"
          v-model="costBasis"
          v-on:input="updateTransactionData"
          :txn="txn"
          :show-cost-basis="false"
        ></cost-basis>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import _ from 'lodash';
import * as math from 'mathjs';

import { convertUnits, getMainUnitForCoin } from '../../../utils/coinUtils';
import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      costBasis: null,
      notes: '',
      splits: [],
    };
  },
  mounted() {
    this.populateForm();
    this.updateTransactionData();
  },
  methods: {
    validateForm() {
      // ensure each split has a contact, and each line has a category (if it has a value)
      let valid = true;
      this.splits.forEach((m) => {
        // for each split, contact must be defined
        if (!m.contact) {
          valid = false;
          m.valid = false;
        }

        m.lines.forEach((l) => {
          // For each line, amount and category must be defined
          if (!l.category) {
            valid = false;
            l.valid = false;
          }

          if (!l.amount) {
            valid = false;
            l.valid = false;
          }
        });
      });

      if (!this.sumValid) {
        valid = false;
      }

      return valid;
    },
    calculateValueInFiat(value) {
      if (this.costBasis) {
        const val = this.costBasis.exchangeRate * value;
        const bn = math.bignumber(val);
        return bn.toDecimalPlaces(2).toNumber();
      } else {
        return 0;
      }
    },
    addSplit() {
      this.splits.push({
        lines: [
          {
            amount: '',
          },
        ],
      });
      this.updateTransactionData();
    },
    addLine(item) {
      item.lines.push({
        amount: '',
      });
      this.updateTransactionData();
    },
    deleteLine(item, line) {
      const newLines = item.lines.filter((m) => m !== line);
      item.lines = newLines;
      this.updateTransactionData();
    },
    deleteSplit(split) {
      const newSplits = this.splits.filter((m) => m !== split);
      this.splits = newSplits;
      this.updateTransactionData();
    },
    populateForm() {
      if (
        this.txn.accountingDetails &&
        this.txn.accountingDetails.length === 1 &&
        this.txn.accountingDetails[0].detailed
      ) {
        const ad = this.txn.accountingDetails[0];
        if (ad.detailed) {
          this.notes = ad.detailed.notes;
          // this.costBasis = ad.detailed.costBasis;
          const splits = ad.detailed.items.map((m) => {
            const lines = m.lines.map((l) => {
              const foundCategory = this.categories.find((cats) => cats.id === l.categoryId);

              return {
                amount: math.bignumber(l.amount.value),
                description: l.description,
                category: foundCategory,
              };
            });

            const foundContact = this.contacts.find((cats) => cats.id === m.contactId);

            return {
              lines,
              contact: foundContact,
            };
          });

          this.splits = splits;
        } else {
          // throw new Error("Bad accounting details");
        }
      } else {
        // Set the defaults based on the txn
        // First, some validation
        // Make sure that all the details we're getting are of the same coin. Not supporting multiple coins in here
        const prePopLines = [];

        if (this.txn && this.txn.details && this.txn.details.length > 0) {
          this.txn.details.forEach((m) => {
            m.fullAmountSet.forEach((a) => {
              const mainUnit = getMainUnitForCoin(this.coin);
              const val = math.bignumber(a.value);
              const conv = convertUnits(this.coin, a.unit, mainUnit, val);
              prePopLines.push({
                amount: conv,
              });
            });
          });
        }

        if (prePopLines.length === 0) {
          prePopLines.push({ amount: math.bignumber(0) });
        }

        this.splits.push({
          lines: prePopLines,
        });
      }
    },
    updateTransactionData() {
      if (this.validateForm()) {
        const transactionData = {};
        transactionData.valid = this.sumValid;

        const items = this.splits.map((m) => {
          const lines = m.lines.map((l) => {
            return {
              categoryId: l.category.id,
              description: l.description,
              amount: {
                value: l.amount.toString(),
                coin: this.coin,
                unit: this.coinUnit,
              },
            };
          });
          return {
            contactId: m.contact.id,
            lines,
          };
        });
        transactionData.detailed = {
          items,
          costBasis: this.costBasis,
        };

        console.log(transactionData);
        this.$emit('input', transactionData);
      } else {
        const transactionData = { valid: false };
        this.$emit('input', transactionData);
      }
    },
  },
  computed: {
    coin() {
      return this.txn.details[0].fullAmountSet[0].coin;
    },
    coinUnit() {
      return getMainUnitForCoin(this.coin);
      // return this.txn.details[0].fullAmountSet[0].unit;
    },
    sumValid() {
      return this.amountTotal.eq(this.txnAmountTotal);
    },
    fiatTotal() {
      let total = 0;
      this.splits.forEach((m) => {
        m.lines.forEach((l) => {
          total += this.calculateValueInFiat(l.amount);
        });
      });

      return math.bignumber(total).toDecimalPlaces(2).toNumber();
    },
    amountTotal() {
      let total = math.bignumber(0);
      this.splits.forEach((m) => {
        m.lines.forEach((l) => {
          if (l.amount) {
            // const val = math.bignumber(l.amount.value);
            // const converted = convertUnits(l.amount.coin, l.amount.unit, this.coinUnit, val);
            // total = total.plus(converted);
            total = total.plus(l.amount);
          }
        });
      });

      return total;
    },
    txnAmountTotal() {
      let amountTotal = math.bignumber(0);

      // this.txn.fees.forEach(m => {
      //   if (m.coin !== this.coin) {
      //     throw new Error("Don't currently support multi coins splitting");
      //   }
      //   const val = math.bignumber(m.value);
      //   const converted = convertUnits(m.coin, m.unit, this.coinUnit, val);
      //   amountTotal = amountTotal.minus(converted);
      // });
      //
      // this.txn.amounts.forEach(m => {
      //   if (m.coin !== this.coin) {
      //     throw new Error("Don't currently support multi coins splitting");
      //   }
      //
      //   const val = math.bignumber(m.value);
      //   const converted = convertUnits(m.coin, m.unit, this.coinUnit, val);
      //   amountTotal = amountTotal.plus(converted);
      // });

      this.txn.details.forEach((m) => {
        m.fullAmountSet.forEach((m) => {
          if (m.coin !== this.coin) {
            throw new Error("Don't currently support multi coins splitting");
          }
          const val = math.bignumber(m.value);
          const converted = convertUnits(m.coin, m.unit, this.coinUnit, val);
          amountTotal = amountTotal.plus(converted);
        });
      });

      return amountTotal;
    },
    filteredCategories() {
      if (this.txnType === 'receive') {
        const filtered = _.filter(this.categories, (m) => ['Revenue', 'Equity', 'Liability', 'Asset'].includes(m.type));
        const sortedByName = _.sortBy(filtered, (m) => m.name);
        return sortedByName;
      } else {
        return _.filter(
          this.categories,
          (m) => m.type === 'Expense' || m.type === 'Asset' || m.type === 'Liability' || m.type === 'Equity'
        );
      }
    },
    filteredContacts() {
      if (this.txnType === 'receive') {
        const filtered = _.filter(this.contacts, (m) => !m.type || m.type === 'None' || m.type === 'Customer');
        const sortedByName = _.sortBy(filtered, (m) => m.name);
        return sortedByName;
      } else {
        const filtered = _.filter(this.contacts, (m) => !m.type || m.type === 'None' || m.type === 'Vendor');
        const sortedByName = _.sortBy(filtered, (m) => m.name);
        return sortedByName;
      }
    },
  },
};
</script>
