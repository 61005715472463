<template>
  <v-layout row wrap>
    <!--<v-flex xs12>-->
    <!--{{txn}}-->
    <!--</v-flex>-->

    <v-flex xs12>
      <v-autocomplete
        v-model="feeContact"
        :items="contacts"
        :label="$tc('_feeContact', 1)"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        :disabled="readonly"
        v-on:change="updateTransactionData"
      >
      </v-autocomplete>
    </v-flex>

    <v-flex xs12>
      <v-textarea
        :label="$tc('_note', 2)"
        solo
        auto-grow
        rows="3"
        v-model="notes"
        :disabled="readonly"
        v-on:input="updateTransactionData"
      ></v-textarea>
    </v-flex>

    <v-flex xs12>
      <cost-basis
        :readonly="readonly"
        ref="cbComponent"
        :amounts="txn.amounts"
        :txn-type="txnType"
        v-model="costBasis"
        v-on:input="updateTransactionData"
        :txn="txn"
        :show-invoice="false"
        :fee-exchange-rates="feeExchangeRates"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import { BigNumber } from 'mathjs';
import * as math from 'mathjs';

import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      costBasis: null,
      notes: '',
      feeContact: null,
    };
  },
  mounted() {
    this.populateForm();
    this.updateTransactionData();
  },
  methods: {
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.transfer) {
          this.notes = ad.transfer.notes;

          if (this.contacts && ad.transfer.feeContactId) {
            const foundContact = this.contacts.find((m) => m.id === ad.transfer.feeContactId);
            this.feeContact = foundContact;
          }
        } else {
          // throw new Error("Bad accounting details");
        }
      }
    },
    updateTransactionData() {
      const transactionData = {};
      transactionData.valid = true;
      const errors = [];
      const disposingWallet = this.txn.txnLines.find((x) => x.operation === ('WITHDRAW' || 'SELL'))?.walletId;

      const mapAsset = (m) => {
        const er = this.bn(this.$refs.cbComponent.exchangeRateForCurrency(m.asset));
        return {
          asset: {
            value: math.bignumber(m.amount).abs().toString(),
            coin: m.asset,
            unit: this.txn.exchangeRates.find((x) => x.coin === m.asset).coinUnit ?? m.asset,
          },
          walletId: m.walletId,
        };
      };

      const mapDetailedFee = (m) => {
        const er = this.bn(this.$refs.cbComponent.exchangeRateForCurrency(m.asset));
        const cost = er.mul(math.bignumber(m.amount));
        return {
          amount: {
            value: math.bignumber(m.amount).abs().toString(),
            coin: m.asset,
            unit: this.txn.exchangeRates.find((x) => x.coin === m.asset).coinUnit ?? m.asset,
          },
          costBasis: {
            cost: cost.abs().toNumber(),
            exchangeRate: er.toNumber(),
            currency: this.$store.state.currentOrg.baseCurrency,
            costBasisType: 'ExchangeRate',
          },
          feeContactId: this.feeContact.id,
          walletId: m.walletId,
        };
      };

      const disposalTxns = this.txn.txnLines.filter((x) => x.operation === 'WITHDRAW');

      const acquiredTxns = this.txn.txnLines.filter((x) => x.operation === 'DEPOSIT');

      const feeTxns = this.txn.txnLines.filter((x) => x.operation === 'FEE');

      const disposedAssets = disposalTxns.map((m) => {
        return mapAsset(m);
      });

      const acquiredAssets = acquiredTxns.map((m) => {
        return mapAsset(m);
      });

      if (!this.costBasis || !this.costBasis.valid) {
        transactionData.valid = false;
        errors.push('Please set a valid cost basis.');
      }

      const netTransferAmounts = (disposedAssets, acquiredAssets) => {
        const netAmountByCoin = new Map();

        disposedAssets.forEach((disposed) => {
          const coin = disposed.asset.coin;
          const value = disposed.asset.value;
          const amount = math.bignumber(value);
          if (!netAmountByCoin.has(coin)) {
            netAmountByCoin.set(coin, math.bignumber(0));
          }
          netAmountByCoin.set(coin, netAmountByCoin.get(coin).minus(amount));
        });

        acquiredAssets.forEach((acquired) => {
          const coin = acquired.asset.coin;
          const value = acquired.asset.value;
          const amount = math.bignumber(value);
          if (!netAmountByCoin.has(coin)) {
            netAmountByCoin.set(coin, math.bignumber(0));
          }
          netAmountByCoin.set(coin, netAmountByCoin.get(coin).plus(amount));
        });

        netAmountByCoin.forEach((value, key) => {
          if (math.equal(value, math.bignumber(0))) {
            netAmountByCoin.delete(key);
          }
        });

        return netAmountByCoin;
      };

      const netAmounts = netTransferAmounts(disposedAssets, acquiredAssets);
      let detailedFees = [];

      if (netAmounts.size > 0) {
        detailedFees = Array.from(netAmounts.entries()).map(([coin, value]) => {
          const er = this.bn(this.$refs.cbComponent.exchangeRateForCurrency(coin));
          const cost = er.mul(math.bignumber(value));
          return {
            amount: {
              value: math.bignumber(value).abs().toString(),
              coin: coin,
              unit: this.txn.exchangeRates.find((x) => x.coin === coin)?.coinUnit ?? coin,
            },
            costBasis: {
              cost: cost.abs().toNumber(),
              exchangeRate: er.toNumber(),
              currency: this.$store.state.currentOrg.baseCurrency,
              costBasisType: 'ExchangeRate',
            },
            feeContactId: this.feeContact?.id,
            walletId: disposingWallet,
            imputed: true,
          };
        });
      }

      if (feeTxns.length > 0) {
        feeTxns.forEach((m) => {
          const detailedFeeObj = mapDetailedFee(m);
          detailedFees.push(detailedFeeObj); // Push the detailedFeeObj to detailedFees array
        });
      }

      let exchangeRates = this.txn?.exchangeRates;

      if (exchangeRates) {
        const updatedExchangeRates = exchangeRates.map(({ coinUnit, __typename, rate, ...rest }) => {
          return { unit: coinUnit, rate: parseFloat(rate), ...rest };
        });

        exchangeRates = updatedExchangeRates;
      } else {
        exchangeRates = this.costBasis.exchangeRates;
      }

      transactionData.transfer = {
        feeCostBasis: this.costBasis,
        notes: this.notes,
        exchangeRates: exchangeRates,
      };
      if (!this.feeContact) {
        transactionData.valid = false;
        errors.push('Fee Contact must be defined');
      } else {
        transactionData.transfer.feeContactId = this.feeContact.id;
      }
      if (disposingWallet) {
        transactionData.transfer.feeWalletId = disposingWallet;
      } else {
        transactionData.valid = false;
        errors.push('Disposing wallet must be defined');
      }
      if (disposedAssets.length === 0) {
        transactionData.valid = false;
        errors.push('No assets to dispose');
      } else {
        transactionData.transfer.disposedAssets = disposedAssets;
      }
      if (acquiredAssets.length === 0) {
        transactionData.valid = false;
        errors.push('No assets to acquire');
      } else {
        transactionData.transfer.acquiredAssets = acquiredAssets;
      }
      if (detailedFees.length > 0) {
        transactionData.transfer.detailedFees = detailedFees;
      }

      this.$emit('input', transactionData);
    },
  },
  computed: {
    feeExchangeRates() {
      // gets the fee exchange rates for the cost basis component
      const feeExchangeRates = {};
      if (this.txn.txnLines?.length) {
        for (const line of this.txn.txnLines) {
          if (line.operation === 'FEE') {
            const ex = this.txn.exchangeRates.find((x) => x.coin === line.asset);
            feeExchangeRates[ex.coin] = ex.coinUnit;
          } else if (line.feeAsset) {
            const ex = this.txn.exchangeRates.find((x) => x.coin === line.feeAsset);
            feeExchangeRates[ex.coin] = ex.coinUnit;
          }
        }
      } else if (this.txn.fullAmountSet) {
        for (const amount of this.txn.fullAmountSet) {
          if (
            !this.txn.fullAmountSetWithoutFees.find(
              (a) => a.coin === amount.coin && a.unit === amount.unit && a.value === amount.value
            )
          ) {
            feeExchangeRates[amount.coin] = amount.unit;
          }
        }
      }
      return Object.keys(feeExchangeRates).length ? feeExchangeRates : null;
    },
  },
};
</script>
