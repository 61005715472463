








































import { bignumber } from 'mathjs';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Category, Connection, Contact, MultiValueTransactionItemInput, Transaction } from '@/api-svc-types';
import { TransactionType } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import { assertDefined } from '@/utils/guards';

import type { CostBasisDTO } from '../types';
import { looselyGetCategoryWithCode, multivalueTransactionDataFactory } from './utilities';

@Component
export default class FeeOnlyCategorization extends BaseVue {
  @Prop({ required: true })
  readonly txn!: Transaction;

  @Prop({ required: true })
  readonly readonly!: boolean;

  @Prop({ required: true })
  readonly costBasis!: CostBasisDTO;

  @Prop({ required: true })
  public readonly categories!: Category[];

  @Prop({ required: true })
  public readonly contacts!: Contact[];

  @Prop({ required: true })
  public readonly accountingConnectionId!: string | null;

  @Prop({ required: true })
  public readonly connection!: Connection | null;

  description = '';
  categoryId: string | null = null;
  contactId: string | null = null;

  mounted() {
    this.populateForm();
    this.updateTransactionData();
  }

  populateForm() {
    if (this.txn.accountingDetails) {
      const { accountingDetails } = this.txn;
      const [ad] = accountingDetails;
      assertDefined(ad);
      const { multivalue } = ad;
      assertDefined(multivalue);

      const { items, notes } = multivalue;
      this.description = notes || '';

      assertDefined(items);
      const [item] = items;
      assertDefined(item);

      const { contactId, lines } = item;
      this.contactId = contactId;

      assertDefined(lines);
      const [line] = lines;

      assertDefined(line);
      this.categoryId = line.categoryId;
    } else {
      const maybeCategory = looselyGetCategoryWithCode(this.categories, this.connection?.feeAccountCode);
      if (maybeCategory) {
        this.categoryId = maybeCategory.id;
      }
    }
  }

  updateTransactionData() {
    if (!this.costBasis || !this.costBasis.valid) {
      this.$emit('input', { valid: false });
      return;
    }

    if (!this.categoryId || !this.contactId) {
      this.$emit('input', { valid: false });
      return;
    }

    const { exchangeRates } = this.costBasis;

    assertDefined(this.txn.amountsIncludingFees);

    const [amount] = this.txn.amountsIncludingFees;

    assertDefined(amount);

    const { value, coin } = amount;

    assertDefined(value);
    assertDefined(coin);

    const item: MultiValueTransactionItemInput = {
      contactId: this.contactId,
      transactionType: TransactionType.Expense,
      lines: [
        {
          categoryId: this.categoryId,
          sourceAmount: value,
          sourceTicker: coin,
          fiat: this.$store.state.currentOrg.baseCurrency,
          fiatAmount: bignumber(this.costBasis.cost).negated().toString(),
        },
      ],
    };

    const transactionData = multivalueTransactionDataFactory([item], exchangeRates);

    this.$emit('input', transactionData);
  }

  @Watch('costBasis')
  watchCostBasis() {
    this.updateTransactionData();
  }
}
