<template>
  <v-layout row wrap>
    <v-alert v-if="errors">
      <ul>
        <li v-for="(error, index) in errors" v-bind:key="'e-' + index">
          {{ error }}
        </li>
      </ul>
    </v-alert>
    <v-flex xs12 mb-3>
      <v-layout row wrap>
        <v-flex xs6>
          <h4>Disposed Assets</h4>
          <li v-for="(a, index) in disposableAmounts" v-bind:key="'da-' + index" style="list-style: none">
            {{ a.amount }}
            {{ a.asset }}
            (${{ calculateValueInFiat(a.asset, a.amount) }})
          </li>
        </v-flex>
        <v-flex xs6>
          <h4>Acquired Assets</h4>
          <li v-for="(a, index) in acquirableAmounts" v-bind:key="'aa-' + index" style="list-style: none">
            {{ a.amount }}
            {{ a.asset }}
            (${{ calculateValueInFiat(a.asset, a.amount) }})
          </li>
        </v-flex>
      </v-layout>
    </v-flex>
    <!-- Disposed -->
    <v-flex my-2 xs12>
      <v-divider></v-divider>
    </v-flex>

    <v-flex my-2 xs12>
      <v-divider></v-divider>
    </v-flex>
    <v-flex xs12 v-if="hasFee">
      <v-layout row wrap>
        <v-flex xs3 mt-3><h2>Fee</h2></v-flex>
        <v-flex xs9>
          <v-autocomplete
            v-model="feeContactId"
            :disabled="readonly"
            :items="contacts"
            :label="'Fee Contact (Exchange or Network)'"
            item-text="name"
            item-value="id"
            persistent-hint
            v-on:change="updateTransactionData"
          >
          </v-autocomplete>
        </v-flex>
        <v-container>
          <v-btn @click="showAdvancedOptions = !showAdvancedOptions" icon small>
            <v-icon small>{{ showAdvancedOptions ? 'fa-chevron-up' : 'fa-chevron-down' }}</v-icon>
          </v-btn>
          <span @click="showAdvancedOptions = !showAdvancedOptions">Advanced Options</span>
          <v-expand-transition>
            <v-flex v-if="showAdvancedOptions">
              <v-checkbox
                v-model="carryForwardCostBasis"
                @change="updateTransactionData"
                label="Carry Forward Cost Basis - No Realized Gain/Loss"
              ></v-checkbox>
            </v-flex>
          </v-expand-transition>
        </v-container>
        <v-flex xs2 offset-xs1><b>Amount</b></v-flex>
        <v-flex xs8>
          <v-layout row wrap>
            <template v-for="(fee, index) in fees">
              <v-flex xs2 v-bind:key="'f1-' + index"
                ><b>{{ fee.coin }}</b></v-flex
              >
              <v-flex xs10 v-bind:key="'f2-' + index"
                ><b>{{ fee.displayValue }}</b></v-flex
              >
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex my-2 xs12>
      <v-divider></v-divider>
    </v-flex>

    <!--        <v-flex xs12 mt-3><h2>Trade Exchange Rate</h2></v-flex>-->
    <!--        <v-flex xs3 offset-xs3><b>{{ exchangeRateUnits }}</b></v-flex>-->
    <!--        <v-flex xs3>{{ exchangeRate }}</v-flex>-->

    <v-flex xs12>
      <cost-basis
        ref="cbComponent"
        :readonly="readonly"
        :txn-type="txnType"
        v-model="costBasis"
        v-on:input="updateTransactionData"
        :txn="txn"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import * as math from 'mathjs';

import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      acquiredCostBasis: null,
      disposedCostBasis: null,
      description: '',
      errors: null,
      feeContactId: '',
      acquiredAmount: null,
      disposedAmount: null,
      acquiredExchangeRateOverride: null,
      acquiredExchangeRateEditing: false,
      disposedExchangeRateOverride: null,
      disposedExchangeRateEditing: false,
      costBasis: null,
      carryForwardCostBasis: false,
      showAdvancedOptions: false,
    };
  },
  mounted() {
    this.populateForm();
    this.updateTransactionData();
  },
  methods: {
    calculateValueInFiat(coin, value) {
      if (!coin || !value) {
        return 0;
      }

      if (this.costBasis) {
        const er = this.$refs.cbComponent.exchangeRateForCurrency(coin);
        const val = this.bn(er).mul(this.bn(value));
        const bn = math.bignumber(val);
        return bn.toDecimalPlaces(2).toNumber();
      } else {
        return 0;
      }
    },
    editAcquiredExchangeRate(er) {
      this.acquiredExchangeRateOverride = er;
      this.acquiredExchangeRateEditing = true;
    },
    editDisposedExchangeRate(er) {
      this.disposedExchangeRateOverride = er;
      this.disposedExchangeRateEditing = true;
    },
    saveEdit(type) {
      if (type === 'disposed') {
        this.disposedExchangeRateEditing = false;
      } else if (type === 'acquired') {
        this.acquiredExchangeRateEditing = false;
      }
      this.updateTransactionData();
    },
    cancelEdit(type) {
      if (type === 'disposed') {
        this.disposedExchangeRateEditing = false;
        this.disposedExchangeRateOverride = null;
      } else if (type === 'acquired') {
        this.acquiredExchangeRateEditing = false;
        this.acquiredExchangeRateOverride = null;
      }
    },
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.trade && ad.trade.tradeFee) {
          this.feeContactId = ad.trade.tradeFee.feeContactId;
        }
        if (ad.trade && ad.trade.carryForwardCostBasis) {
          this.carryForwardCostBasis = ad.trade.carryForwardCostBasis;
        }
      } else if (this.txn.txnLines?.length) {
        const disposable = [];
        const aquireable = [];
        this.txn.txnLines.forEach((m) => {
          const val = math.bignumber(m.operation === 'DEPOSIT' || m.operation === 'BUY' ? m.amount : '-' + m.amount);
          if (val.lessThan(0)) {
            disposable.push(m);
          } else if (val.greaterThan(0)) {
            aquireable.push(m);
          }
        });

        if (disposable.length === 1) {
          this.disposedAmount = disposable[0];
        }

        if (aquireable.length === 1) {
          this.acquiredAmount = aquireable[0];
        }
        const org = this.$store.state.currentOrg;
        if (org.accountingConfig && org.accountingConfig.networkContactIds) {
          const networkContactId = org.accountingConfig.networkContactIds[this.txn.networkId];
          if (networkContactId) {
            this.feeContactId = networkContactId;
          }
        }
      } else {
        const disposable = this.txn.amounts.filter((m) => math.bignumber(m.value).lessThan(0));
        if (disposable.length === 1) {
          this.disposedAmount = disposable[0];
        }

        const aquireable = this.txn.amounts.filter((m) => math.bignumber(m.value).greaterThan(0));
        if (aquireable.length === 1) {
          this.acquiredAmount = aquireable[0];
        }

        const org = this.$store.state.currentOrg;
        if (org.accountingConfig && org.accountingConfig.networkContactIds) {
          const networkContactId = org.accountingConfig.networkContactIds[this.txn.networkId];
          if (networkContactId) {
            this.feeContactId = networkContactId;
          }
        }
      }
    },
    updateTransactionData() {
      const transactionData = {};
      transactionData.valid = true;
      if (this.txn.accountingDetails && this.txn.accountingDetails[0] && this.txn.accountingDetails.systemCreated) {
        return;
      }

      if (this.disposableAmounts && this.acquirableAmounts) {
        const mapAsset = (m) => {
          const er = this.bn(this.$refs.cbComponent.exchangeRateForCurrency(m.asset));
          const cost = er.mul(math.bignumber(m.amount));
          // const bn = math.bignumber(val);
          // return bn.toDecimalPlaces(2).toNumber();

          return {
            asset: {
              value: math.bignumber(m.amount).abs().toString(),
              coin: m.asset,
              unit: this.txn.exchangeRates.find((x) => x.coin === m.asset).coinUnit ?? m.asset,
            },
            fairMarketValue: {
              cost: cost.abs().toNumber(),
              exchangeRate: er.toNumber(),
              currency: this.$store.state.currentOrg.baseCurrency,
              costBasisType: 'ExchangeRate',
            },
            walletId: m.walletId,
          };
        };

        const acquiredAssets = this.acquirableAmounts.map((m) => {
          return mapAsset(m);
        });

        const disposedAssets = this.disposableAmounts.map((m) => {
          return mapAsset(m);
        });

        const exchangeRates = [];
        for (const er of this.$refs.cbComponent.exchangeRates) {
          exchangeRates.push({
            coin: er.coin,
            unit: er.unit,
            fiat: er.fiat,
            rate: er.rate,
            source: er.source,
          });
        }

        transactionData.trade = {
          acquiredAssets,
          disposedAssets,
          exchangeRates: exchangeRates,
        };

        if (this.fees.length > 0) {
          if (!this.feeContactId) {
            transactionData.valid = false;
          }
          /*
          amount: UncheckedAmountInput
        costBasis: CostBasisInput!
        feeContactId: String!
           */
          const feeAssets = this.fees.map((f) => {
            const feeNum = math.bignumber(f.amount);
            if (!feeNum.eq(0)) {
              const fee = mapAsset(f);
              // const fee = {
              //   asset: {
              //     value: math
              //       .bignumber(f.value)
              //       .abs()
              //       .toString(),
              //     coin: f.coin,
              //     unit: f.unit
              //   },
              //   fairMarketValue: {
              //     cost: math
              //       .bignumber(f.fiatValue.value)
              //       .abs()
              //       .toNumber(),
              //     exchangeRate: f.fiatValue.exchangeRate.rate,
              //     currency: f.fiatValue.currency,
              //     costBasisType: "ExchangeRate"
              //   }
              // };
              return fee;
            }
          });

          const paidFees = feeAssets.filter((m) => m !== null && m !== undefined);
          if (paidFees.length > 0) {
            transactionData.trade.tradeFee = {
              assets: paidFees,
              feeContactId: this.feeContactId,
            };
          }
        }

        // this means we need to handle fiat fees paid (they don't come in as fees through txnLines)
        // can only handle 1 fee
        if (this.txn.paidFees && this.fees.length === 0 && this.txn.paidFees.length === 1) {
          const er = this.bn(this.$refs.cbComponent.exchangeRateForCurrency(this.txn.paidFees[0].coin));
          const cost = math.bignumber(this.txn.paidFees[0].value).abs().mul(er);
          const paidFees = {
            asset: {
              value: math.bignumber(this.txn.paidFees[0].value).abs().toString(),
              coin: this.txn.paidFees[0].coin,
              unit: this.txn.paidFees[0].unit,
            },
            fairMarketValue: {
              cost: cost.abs().toNumber(),
              exchangeRate: er.toNumber(),
              currency: this.$store.state.currentOrg.baseCurrency,
              costBasisType: 'ExchangeRate',
            },
            walletId: this.walletId,
          };
          transactionData.trade.tradeFee = {
            assets: [paidFees],
            feeContactId: this.feeContactId,
          };
        }
        const carryForwardCostBasis = this.carryForwardCostBasis;
        transactionData.trade.carryForwardCostBasis = carryForwardCostBasis;

        this.$emit('input', transactionData);
      } else {
        this.errors = ['More than one acquired amount, disposed amount or fee present - unable to treat as trade'];
      }
    },
  },
  computed: {
    hasFee() {
      return this.txn.paidFees.length > 0;
    },
    disposableAmounts() {
      // foreach paid fee, find the txnLine that matches the fee value => amount and coin => asset and set its operation to FEE. only do this if there are no txnLines with operation FEE
      if (!this.txn.txnLines.some((x) => x.operation === 'FEE' || (x.feeAmount && x.feeAsset))) {
        this.txn.paidFees.forEach((fee) => {
          const feeAmount = math.bignumber(fee.value);
          const feeAsset = fee.coin;
          const feeLine = this.txn.txnLines
            .filter((l) => l.operation !== 'FEE')
            .find((line) => {
              const lineAmount = math.bignumber(line.amount);
              const lineAsset = line.asset;
              return feeAmount.eq(lineAmount) && feeAsset === lineAsset;
            });
          if (feeLine) {
            feeLine.operation = 'FEE';
          }
        });
      }

      const positiveTxnLines = this.txn.txnLines.filter(
        (line) =>
          math.bignumber(line.amount).greaterThan(0) && (line.operation === 'WITHDRAW' || line.operation === 'SELL')
      );

      const groupedByWalletAndAsset = positiveTxnLines.reduce((result, line) => {
        const key = `${line.walletId}${line.asset}`;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(line);
        return result;
      }, {});

      const mapped = Object.values(groupedByWalletAndAsset).map((group) => {
        const totalAmount = group.reduce((sum, line) => math.bignumber(line.amount).add(sum), math.bignumber(0));
        const { asset, walletId } = group[0];
        return {
          amount: totalAmount,
          asset,
          walletId,
          text: `${asset} -${totalAmount}`,
        };
      });

      return mapped;
    },
    acquirableAmounts() {
      // foreach paid fee, find the txnLine that matches the fee value => amount and coin => asset and set its operation to FEE. only do this if there are no txnLines with operation FEE
      if (!this.txn.txnLines.some((x) => x.operation === 'FEE' || (x.feeAmount && x.feeAsset))) {
        this.txn.paidFees.forEach((fee) => {
          const feeAmount = math.bignumber(fee.value);
          const feeAsset = fee.coin;
          const feeLine = this.txn.txnLines
            .filter((l) => l.operation !== 'FEE')
            .find((line) => {
              const lineAmount = math.bignumber(line.amount);
              const lineAsset = line.asset;
              return feeAmount.eq(lineAmount) && feeAsset === lineAsset;
            });
          if (feeLine) {
            feeLine.operation = 'FEE';
          }
        });
      }
      const positiveTxnLines = this.txn.txnLines.filter(
        (line) =>
          math.bignumber(line.amount).greaterThan(0) && (line.operation === 'DEPOSIT' || line.operation === 'BUY')
      );

      const groupedByWalletAndAsset = positiveTxnLines.reduce((result, line) => {
        const key = `${line.walletId}${line.asset}`;
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(line);
        return result;
      }, {});

      const mapped = Object.values(groupedByWalletAndAsset).map((group) => {
        const totalAmount = group.reduce((sum, line) => math.bignumber(line.amount).add(sum), math.bignumber(0));
        const { asset, walletId } = group[0];
        return {
          amount: totalAmount,
          asset,
          walletId,
          text: `${asset} ${totalAmount}`,
        };
      });

      return mapped;
    },
    exchangeRate() {
      if (this.acquiredAmount && this.disposedAmount) {
        const acquiredVal = math.bignumber(this.acquiredAmount.value);
        const disposedVal = math.bignumber(this.disposedAmount.value);

        if (acquiredVal.abs().greaterThan(disposedVal.abs())) {
          const calc = acquiredVal.div(disposedVal).abs().toFixed(2);
          return calc;
        } else {
          const calc = disposedVal.div(acquiredVal).abs().toFixed(2);
          return calc;
        }
      } else {
        return null;
      }
    },
    exchangeRateUnits() {
      if (this.acquiredAmount && this.disposedAmount) {
        const acquiredVal = math.bignumber(this.acquiredAmount.value);
        const disposedVal = math.bignumber(this.disposedAmount.value);
        if (acquiredVal.abs().greaterThan(disposedVal.abs())) {
          return this.acquiredAmount.coin + ' / ' + this.disposedAmount.coin;
        } else {
          return this.disposedAmount.coin + ' / ' + this.acquiredAmount.coin;
        }
      } else {
        return null;
      }
    },
    fees() {
      if (
        this.txn.accountingDetails &&
        this.txn.accountingDetails[0] &&
        this.txn.accountingDetails[0].trade &&
        this.txn.accountingDetails[0].trade.fee
      ) {
        const ad = this.txn.accountingDetails[0];
        if (ad.trade.fee) {
          return [{ ...ad.trade.fee.fee, amount: ad.trade.fee.fee.amount.toString(), asset: ad.trade.fee.fee.coin }];
        } else {
          return [];
        }
      } else if (this.txn.txnLines?.length) {
        return this.txn.txnLines.reduce((a, x) => {
          if (x.operation === 'FEE') {
            a.push(x);
          } else if (x.feeAmount || x.feeAsset) {
            a.push({
              ...x,
              amount: x.feeAmount,
              asset: x.feeAsset,
              walletId: x.walletId,
              value: x.feeAmount,
              coin: x.feeAsset,
            });
          }
          return a;
        }, []);
      } else {
        return [];
        // const fees = this.txn.amounts.filter(
        //   m => m !== this.acquiredAmount && m !== this.disposedAmount
        // );
        // return fees;
      }
    },
  },
};
</script>
