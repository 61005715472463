<template>
  <v-tooltip top>
    <v-icon
      v-if="visible"
      slot="activator"
      :size="iconSize"
      style="cursor: pointer"
      v-clipboard:copy="content"
      v-clipboard:success="onCopySuccess"
      v-clipboard:error="onCopyError"
    >
      content_copy
    </v-icon>
    <span v-if="visible">{{ tooltipMessage }}</span>
  </v-tooltip>
</template>
<script>
import { MUT_SNACKBAR } from '../store';

export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    iconSize: {
      type: Number,
      required: false,
      default: 14,
    },
    tooltipMessage: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  methods: {
    onCopySuccess() {
      this.$store.commit(MUT_SNACKBAR, {
        color: 'success',
        message: this.$t('_clipboardSuccess'),
      });
    },
    onCopyError(e) {
      console.log('Copy clipboard fail', e);
      this.$store.commit(MUT_SNACKBAR, {
        color: 'error',
        message: this.$t('_clipboardFail'),
      });
    },
  },
};
</script>
