import { split } from 'lodash';
import * as math from 'mathjs';

import type {
  Amount,
  Category,
  ExchangeRateInput,
  Maybe,
  MultiValueTransactionInput,
  MultiValueTransactionItemInput,
  MultiValueTransactionLineInput,
  TxnLine,
} from '@/api-svc-types';
import { TransactionType } from '@/api-svc-types';
import { convertUnits, getMainUnitForCoin } from '@/utils/coinUtils';
import { assertDefined } from '@/utils/guards';

import type { CostBasisDTO } from '../types';
import type { MultivalueTransactionData, Split, SplitLine } from './types';

const exchangeRateForCurrency = (exchangeRates: ExchangeRateInput[], currency: string) => {
  // get selected exchange rate
  if (exchangeRates) {
    const ro = exchangeRates.find((m) => m.coin === currency); // .get(currency);
    if (ro === null || ro === undefined || isNaN(Number(ro.rate))) {
      return 0;
    } else {
      return Number(ro.rate);
    }
  } else {
    return 0;
  }
};

export const calculateValueInFiat = (costBasis: CostBasisDTO, coin: string, value?: math.BigNumber) => {
  if (!coin || !value) {
    return 0;
  }

  if (costBasis) {
    const er = exchangeRateForCurrency(costBasis.exchangeRates, coin);
    const val = math.bignumber(er).mul(value);
    return val.toDecimalPlaces(2).toNumber();
  } else {
    return 0;
  }
};

const splitlineToMultiValueTransactionLineInput = (
  splitline: SplitLine,
  currency: string,
  costBasis: CostBasisDTO,
  transactionType?: string
): MultiValueTransactionLineInput => {
  const { category, description, ticker, amount, metadata } = splitline;

  assertDefined(category);
  assertDefined(ticker);
  assertDefined(amount);

  const result = {
    categoryId: category.id,
    sourceTicker: ticker,
    sourceAmount: amount.toString(),
    fiat: currency,
    fiatAmount: calculateValueInFiat(costBasis, ticker, amount).toString(),
    txnLineId: splitline.txnLineId,
    walletId: splitline.walletId,
    forceZeroGainLoss: splitline.forceZeroGainLoss,
  };

  if (transactionType === 'ContractExecution') {
    return result;
  }

  return {
    ...result,
    description: description || '',
    metadataIds: metadata || [],
  };
};

export const splitToMultiValueTransactionItemInput = (
  split: Split,
  txnAmountTotal: number,
  currency: string,
  costBasis: CostBasisDTO,
  transactionType?: string
): MultiValueTransactionItemInput => {
  const { contact, lines: splitLines } = split;

  assertDefined(contact);

  const lines = splitLines.map((line) =>
    splitlineToMultiValueTransactionLineInput(line, currency, costBasis, transactionType)
  );
  return {
    contactId: contact.id,
    transactionType: txnAmountTotal > 0 ? TransactionType.Income : TransactionType.Expense,
    lines,
  };
};

export const multivalueTransactionDataFactory = (
  items: MultiValueTransactionInput['items'],
  exchangeRates: MultiValueTransactionInput['exchangeRates']
): MultivalueTransactionData => {
  return {
    valid: true,
    multivalue: {
      items,
      exchangeRates,
    },
  };
};

export const prepopulateLineFromAmount = (amount: Maybe<Amount>) => {
  assertDefined(amount);
  const { coin, value, unit } = amount;
  assertDefined(coin);
  assertDefined(unit);
  const mainUnit = getMainUnitForCoin(coin);
  const val = math.bignumber(value);
  const conv = convertUnits(coin, unit, mainUnit, val);
  return {
    amount: conv,
    ticker: coin,
  };
};

export const prepopulateLineFromTxnLine = (txnLine: Maybe<TxnLine>) => {
  assertDefined(txnLine);
  const { amount, asset, operation } = txnLine;
  assertDefined(amount);
  assertDefined(asset);
  assertDefined(operation);
  const mainUnit = getMainUnitForCoin(asset);
  const val = math.bignumber(operation === 'DEPOSIT' ? amount : '-' + amount);
  const conv = convertUnits(asset, mainUnit, mainUnit, val);
  return {
    amount: conv,
    ticker: asset,
  };
};

export const looselyGetCategoryWithCode = (categories: Category[], accountCode: Maybe<string> | undefined) => {
  if (!accountCode) {
    return null;
  }

  const found = categories.filter((c) => c.code === accountCode);

  if (found.length === 0) {
    return null;
  } else {
    return found[0];
  }
};
