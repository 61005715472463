





















































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import type { Category, Connection, Contact, Metadata, Transaction, TransactionData, Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import CostBasis from '../CostBasis.vue';
import type { CostBasisDTO } from '../types';
import FeeOnlyCategorization from './FeeOnlyCategorization.vue';
import MultiValueCategorization from './MultiValueCategorization.vue';
import SimpleCategorization from './SimpleCategorization.vue';

type MetadataByType = {
  type: string;
  metadata: Metadata[];
};

@Component({
  components: {
    CostBasis,
    MultiValueCategorization,
    SimpleCategorization,
    FeeOnlyCategorization,
  },
})
export default class StandardCategorizationContainer extends BaseVue {
  @Prop({ required: true })
  public readonly categories!: Category[];

  @Prop({ required: true })
  public readonly contacts!: Contact[];

  @Prop({ required: true })
  public readonly txn!: Transaction;

  @Prop({ required: true })
  public readonly txnType!: string;

  @Prop({ required: true })
  public readonly readonly!: boolean;

  @Prop({ required: true })
  public readonly accountingConnectionId!: string | null;

  @Prop({ required: true })
  public readonly connections!: Connection[];

  @Prop({ required: true })
  public readonly getContact?: (transaction: Transaction) => string;

  @Prop({ required: true })
  public readonly metadata!: MetadataByType[];

  @Prop({ required: true })
  public readonly wallets!: Wallet[];

  costBasis: CostBasisDTO | null = null;

  currentCategorizationMethod: 'simple' | 'multivalue' | 'feeOnly' = 'multivalue';

  updateCostBasis(costBasis: CostBasisDTO) {
    this.costBasis = costBasis;
  }

  updateTransactionData(transactionData: TransactionData) {
    this.$emit('input', transactionData);
  }

  get wasCategorizedWithFeeOnly() {
    //  if we had categorized a transaction
    //  we need to make sure there is 1 split and 1 line
    if (this.txn.accountingDetails) {
      const [ad] = this.txn.accountingDetails;

      if (
        ad?.multivalue &&
        ad?.multivalue.items.length === 1 &&
        ad?.multivalue.items[0]?.lines.length === 1 &&
        this.txn.paidFees?.length &&
        this.txn.fullAmountSetWithoutFees?.length === 0
      ) {
        return true;
      }
    }

    return false;
  }

  get existingCategorizationMethod() {
    if (this.txn && this.txn.accountingDetails && this.txn.accountingDetails.length) {
      const [ad] = this.txn.accountingDetails;

      if (ad?.simple) {
        return 'simple';
      }

      if (ad?.multivalue) {
        if (this.wasCategorizedWithFeeOnly) {
          return 'feeOnly';
        }
        return 'multivalue';
      }
    }

    return null;
  }

  get supportedCategorizationOptions() {
    const simple = { label: 'Simple', value: 'simple' };
    const multivalue = { label: 'Detailed', value: 'multivalue' };
    const feeOnly = { label: 'Fee Only', value: 'feeOnly' };

    const options = [null, multivalue];

    if (this.canShowSimple) {
      options[0] = simple;
      return options;
    }

    if (this.canShowFeeOnly) {
      options[0] = feeOnly;
      return options;
    }

    return options.filter((o) => Boolean(o));
  }

  get canShowSimple() {
    //  simple is only for historical view only, will not allow categorization
    if (this.existingCategorizationMethod === 'simple') {
      return true;
    }

    return false;
  }

  get canShowFeeOnly() {
    if (this.wasCategorizedWithFeeOnly) {
      return true;
    }

    //  show fee only when there is no amount and only fee
    if (this.txn.amountsIncludingFees?.length === 1 && this.txn.amounts?.length === 0) {
      return true;
    }

    return false;
  }

  get canShowCategorizationOptions() {
    if (this.readonly) {
      return false;
    }

    //  this means only 1 option available, no need for radio buttons
    if (this.supportedCategorizationOptions.length === 1) {
      return false;
    }

    return true;
  }

  get currentSelectedAccountingConnection() {
    return this.connections.find((c) => c.id === this.accountingConnectionId) ?? null;
  }

  mounted() {
    if (this.existingCategorizationMethod) {
      this.currentCategorizationMethod = this.existingCategorizationMethod;
      return;
    }

    if (this.canShowSimple) {
      this.currentCategorizationMethod = 'simple';
    }

    if (this.canShowFeeOnly) {
      this.currentCategorizationMethod = 'feeOnly';
    }
  }
}
