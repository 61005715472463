





































































































import gql from 'graphql-tag';
import * as math from 'mathjs';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type {
  AdvanceDeFiLine,
  AdvanceDeFiLineNonPost,
  AdvanceDeFiLinePost,
  Category,
  Contact,
  ExchangeRateDetails,
  Metadata,
  Transaction,
  Wallet,
} from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import CostBasis from '../CostBasis.vue';
import type { CostBasisDTO } from '../types';
import type { AdvanceDeFiItemInput, AdvanceDeFiLineNonPostInput, AdvanceDeFiLinePostInput } from './types';

type MetadataByType = {
  type: string;
  metadata: Metadata[];
};

@Component({
  apollo: {
    metadata: {
      query: gql`
        query getMetadata($orgId: ID!, $connectionId: ID, $includeDisabled: Boolean) {
          metadata(orgId: $orgId, connectionId: $connectionId, includeDisabled: $includeDisabled) {
            id
            enabled
            source
            metaType
            name
            remoteType
            connectionId
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
          connectionId: this.accountingConnectionId,
          includeDisabled: false,
        };
      },
      update(data) {
        if (!data.metadata) return [];
        const metadataObj: Record<string, Metadata[]> = {};
        data.metadata.forEach((m: Metadata | null) => {
          if (!m) return;
          if (!metadataObj[m.remoteType]) metadataObj[m.remoteType] = [];
          metadataObj[m.remoteType].push(m);
        });
        const metadataByType = Object.entries(metadataObj).map(([type, metadata]) => {
          return {
            type,
            metadata,
          };
        });
        return metadataByType;
      },
    },
  },
  components: {
    CostBasis,
  },
})
export default class AdvancedDeFiCategorization extends BaseVue {
  @Prop({ required: true })
  readonly txn!: Transaction;

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: true })
  readonly contacts!: Contact[];

  @Prop({ required: true })
  readonly txnType!: string;

  @Prop({ required: true })
  readonly readonly!: boolean;

  @Prop({ required: true })
  public readonly accountingConnectionId!: string | null;

  @Prop({ required: true })
  public readonly wallets!: Wallet[];

  costBasis: CostBasisDTO | null = null;
  splits: AdvanceDeFiItemInput[] = [];
  metadata: MetadataByType[] = [];
  feeContactId: null | string = null;

  mounted() {
    this.populateForm();
    if (this.splits.length === 0) {
      this.addSplit();
      const splitIndex = 0;
      const split = this.splits[splitIndex];
      if (!split) return;
      if (this.txn.txnLines?.length) {
        const amounts = this.txn.txnLines.filter((l) => l?.operation.toUpperCase() !== 'FEE');

        // avoid issues with different operation case.
        // const fees = this.txn.paidFees;
        const exchangeRates = this.txn.exchangeRates;
        if (amounts) {
          amounts
            .filter((x) => x?.amount && x?.amount !== '0')
            .forEach((amount, index) => {
              this.addLine(splitIndex, false);
              if (split && split.nonpostingLines) {
                const direction = amount?.operation.toUpperCase() === 'WITHDRAW' ? -1 : 1;
                split.nonpostingLines[index] = {
                  sourceTicker: amount?.asset ?? '',
                  sourceAmount: amount?.amount ? math.bignumber(amount?.amount).mul(direction).toString() : '',
                  walletId: amount?.walletId ?? '',
                  isNonVaultTracking: false,
                };
              }
            });
        }
      } else {
        const amounts = this.txn.fullAmountSetWithoutFees;
        // const fees = this.txn.paidFees;
        const exchangeRates = this.txn.exchangeRates;
        if (amounts) {
          amounts.forEach((amount, index) => {
            this.addLine(splitIndex, false);
            if (split && split.nonpostingLines) {
              split.nonpostingLines[index] = {
                sourceTicker: amount?.coin ?? '',
                sourceAmount: amount?.value ?? '',
                isNonVaultTracking: false,
              };
            }
          });
        }
      }
      /*
      if (fees) {
        fees.forEach((fee, index) => {
          this.addLine(splitIndex, true);
          let fiatAmount = '';
          if (exchangeRates) {
            const er = exchangeRates?.find((rate: ExchangeRateDetails | null) => rate?.coin === fee?.coin);
            if (er) {
              fiatAmount = math.bignumber(fee?.value).mul(er.rate).neg().toString();
            }
          }
          if (split && split.postingLines) {
            split.postingLines[index] = {
              sourceTicker: fee?.coin ?? '',
              sourceAmount: fee ? math.bignumber(fee.value).neg().toString() : '',
              isNonVaultTracking: true,
              contactId: '',
              categoryId: '',
              description: '',
              fiat: this.$store.state.currentOrg.baseCurrency,
              fiatAmount,
              metadataIds: [],
            };
          }
        });
      }
      */
    }
    this.updateTransactionData();
  }

  updateCostBasis(costBasis: CostBasisDTO) {
    this.costBasis = costBasis;
  }

  validateForm() {
    // ensure each split has a contact, and each line has a category (if it has a value)
    let valid = true;
    this.splits.forEach((m) => {
      // for each split, contact must be defined
      if (!m.defiWalletId) {
        valid = false;
      }

      /*
      if (m.postingLines) {
        m.postingLines.forEach((l) => {
          // For each line, amount and category must be defined
          if (!l.contactId || !l.categoryId || !l.sourceTicker || !l.sourceAmount || !l.fiat || !l.fiatAmount) {
            valid = false;
          }
        });
      }
      */

      if (m.nonpostingLines) {
        m.nonpostingLines.forEach((l) => {
          // For each line, amount and category must be defined
          if (!l.sourceTicker || !l.sourceAmount) {
            valid = false;
          }
        });
      }
    });

    return valid;
  }

  addSplit() {
    this.splits.push({
      defiWalletId: '',
    });
    this.updateTransactionData();
  }

  addLine(splitIndex: number, posting: boolean) {
    if (posting) {
      if (!this.splits[splitIndex].postingLines) this.splits[splitIndex].postingLines = [];
      this.splits[splitIndex].postingLines?.push({
        contactId: '',
        categoryId: '',
        description: '',
        sourceTicker: '',
        sourceAmount: '',
        fiat: this.$store.state.currentOrg.baseCurrency,
        fiatAmount: '',
        metadataIds: [],
        isNonVaultTracking: true,
        walletId: '',
      });
    } else {
      if (!this.splits[splitIndex].nonpostingLines) this.splits[splitIndex].nonpostingLines = [];
      this.splits[splitIndex].nonpostingLines?.push({
        sourceTicker: '',
        sourceAmount: '',
        isNonVaultTracking: false,
        walletId: '',
      });
    }
    this.updateTransactionData();
    this.$forceUpdate();
  }

  deleteLine(item: AdvanceDeFiItemInput, lineIndex: number, posting: boolean) {
    if (posting) {
      if (!item.postingLines) return;
      item.postingLines.splice(lineIndex, 1);
    } else {
      if (!item.nonpostingLines) return;
      item.nonpostingLines.splice(lineIndex, 1);
    }
    this.updateTransactionData();
    this.$forceUpdate();
  }

  deleteSplit(split: AdvanceDeFiItemInput) {
    const newSplits = this.splits.filter((m) => m !== split);
    this.splits = newSplits;
    this.updateTransactionData();
  }

  moveLine(splitIndex: number, lineIndex: number, posting: boolean) {
    const item: AdvanceDeFiItemInput = this.splits[splitIndex];
    const line = posting
      ? item && item.postingLines
        ? { ...item.postingLines[lineIndex] }
        : null
      : item && item.nonpostingLines
      ? { ...item.nonpostingLines[lineIndex] }
      : null;
    if (!line) return;
    this.deleteLine(item, lineIndex, posting);
    this.addLine(splitIndex, !posting);
    if (!posting) {
      // move to posting
      const lines = this.splits[splitIndex].postingLines;
      if (!lines?.length) return;
      const lineLen = lines.length;
      const exchangeRates = this.txn.exchangeRates;
      let fiatAmount = '';
      if (exchangeRates) {
        const er = exchangeRates?.find((rate: ExchangeRateDetails | null) => rate?.coin === line.sourceTicker);
        if (er) {
          fiatAmount = math.bignumber(line.sourceAmount).mul(er.rate).toString();
        }
      }
      if (lines) {
        lines[lineLen - 1] = {
          ...lines[lineLen - 1],
          sourceTicker: line.sourceTicker,
          sourceAmount: line.sourceAmount,
          fiatAmount,
          isNonVaultTracking: true,
          walletId: line.walletId,
        };
      }
    } else {
      // move to nonposting
      const lines = this.splits[splitIndex].nonpostingLines;
      if (!lines?.length) return;
      const lineLen = lines.length;
      if (lines) {
        lines[lineLen - 1] = {
          sourceTicker: line.sourceTicker,
          sourceAmount: line.sourceAmount,
          isNonVaultTracking: false,
          walletId: line.walletId,
        };
      }
    }
    this.updateTransactionData();
  }

  populateForm() {
    if (!this.txn.accountingDetails || this.txn.accountingDetails.length !== 1) {
      return;
    }
    const ad = this.txn.accountingDetails[0];
    if (!ad || !ad.advanceDeFi || !ad.advanceDeFi.items) return;
    const feePayeeId = ad.advanceDeFi.fees?.[0].feePayeeId;
    if (feePayeeId) {
      this.feeContactId = feePayeeId;
    }
    this.splits = ad.advanceDeFi.items.map((item) => {
      const postingLines: AdvanceDeFiLinePostInput[] = [];
      const nonpostingLines: AdvanceDeFiLineNonPostInput[] = [];
      item.lines.forEach((line: AdvanceDeFiLine) => {
        if ((line as AdvanceDeFiLinePost).contactId) {
          const { type, __typename, metadata, isNonVaultTracking, ...line_ } = line as AdvanceDeFiLinePost;
          const metadataIds: string[] = (metadata?.map((m) => m?.id).filter((id) => id) as string[]) ?? [];
          postingLines.push({ ...line_, isNonVaultTracking: !!isNonVaultTracking, metadataIds });
        } else {
          const { type, __typename, ...line_ } = line;
          nonpostingLines.push(line_ as AdvanceDeFiLineNonPostInput);
        }
      });
      return {
        defiWalletId: item.defiWalletId,
        postingLines,
        nonpostingLines,
      };
    });
  }

  updateLineSourceData(line: AdvanceDeFiLinePostInput) {
    const { exchangeRates } = this.txn;
    if (exchangeRates) {
      const er = exchangeRates?.find((rate: ExchangeRateDetails | null) => rate?.coin === line.sourceTicker);
      if (er) {
        line.fiatAmount = math.bignumber(line.sourceAmount).mul(er.rate).toString();
        this.$forceUpdate();
      }
      this.updateTransactionData();
    }
  }

  updateTransactionData() {
    if (!this.validateForm()) {
      this.$emit('input', { valid: false });
      return;
    }
    const baseCurrency = this.$store.state.currentOrg.baseCurrency;

    try {
      const items = this.splits;
      const exchangeRates = [];
      let detailedFees: any[] = [];
      if (this.costBasis) {
        for (const er of this.costBasis.exchangeRates) {
          exchangeRates.push({
            coin: er.coin,
            unit: er.unit,
            fiat: er.fiat,
            rate: er.rate,
            source: er.source,
          });
        }
        if (this.costBasis.fees && this.costBasis.fees.length > 0) {
          if (this.feeContactId === null) {
            return this.$emit('input', { valid: false });
          }
          const feeContactId = this.feeContactId;

          const feeTxns = this.txn?.txnLines?.filter((x) => x?.operation === 'FEE');
          let feeWalletId: string | undefined;
          if (feeTxns && feeTxns.length === 1) {
            feeWalletId = feeTxns[0]?.walletId;
          }

          detailedFees = this.costBasis.fees.map((m) => ({
            amount: m.amount,
            costBasis: m.costBasis,
            feeContactId: feeContactId,
            walletId: feeWalletId,
          }));
        }
      }
      const transactionData = {
        valid: true,
        advanceDeFi: {
          items,
          exchangeRates,
          fees: detailedFees,
        },
      };
      this.$emit('input', transactionData);
    } catch (e) {
      console.log('e', e);
      this.$emit('input', { valid: false });
    }
  }

  get coins() {
    const { exchangeRates } = this.txn;
    return exchangeRates?.map((rate: ExchangeRateDetails | null) => rate?.coin).filter((el) => el) ?? [];
  }

  get filteredCategories() {
    return this.categories;
  }

  get filteredContacts() {
    return this.contacts;
  }

  get defiWallets() {
    return this.wallets.filter((w) => w.type === 22);
  }

  @Watch('costBasis')
  watchCostBasis() {
    this.updateTransactionData();
  }

  @Watch('metadata')
  watchMetadata() {
    for (const split of this.splits) {
      if (split.postingLines) {
        for (const line of split.postingLines) {
          if (line && this.metadata && this.metadata.length !== line?.metadataIds?.length) {
            line.metadataIds = new Array(this.metadata.length).fill('');
          }
        }
      }
    }
  }
}
