import crypto from 'crypto';

/**
 * @deprecated Use type coercion instead: `!maybeString`, or `!!maybeString` to check if string is **not** empty
 */
export function isStrEmpty(val: string) {
  if (val === null || val === undefined || val === '') {
    return true;
  } else {
    return false;
  }
}

/**
 * Trims long strings down to a maximum length, adding "..." to the string if it was trimmed.
 * The maximum length includes the length of the ellipsis ("..."), if it is added.
 * @param val The string to trim
 * @param maxLength The maximum (inclusive) length of the string
 * @returns The trimmed string
 */
export function ellipsis(val: string, maxLength: number) {
  if (val.length <= maxLength) {
    return val;
  }

  return val.substr(0, maxLength - 3) + '...';
}

export const getEmptyStringProps = (obj: Record<string, any>): Record<string, any> => {
  const emptyStringProps: string[] = [];
  for (const prop in obj) {
    if (typeof obj[prop] === 'string' && obj[prop].trim() === '') {
      emptyStringProps.push(prop);
    }
  }
  return emptyStringProps;
};

export async function secureRandomString(length: number): Promise<string> {
  const p = new Promise<string>((resolve, reject) => {
    crypto.randomBytes(length, (err: Error | null, buffer: Buffer) => {
      if (err) {
        reject(err); // Reject with the error if it occurs
      } else {
        const token = buffer.toString('hex');
        resolve(token.substring(0, length));
      }
    });
  });

  return p;
}

export const toTitleCase = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
