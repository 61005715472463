<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-autocomplete
        :items="filteredCategories"
        :label="$tc('_assetCategory', 1)"
        v-model="category"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        :disabled="readonly"
        v-on:change="updateTransactionData"
      >
        <template slot="item" slot-scope="data">
          <template>
            <v-list-tile-content
              >{{ data.item.name }} <sub>{{ data.item.type }}</sub></v-list-tile-content
            >
          </template>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="contact"
        :items="filteredContacts"
        :label="$tc('_contact', 1)"
        item-text="name"
        item-value="id"
        persistent-hint
        return-object
        :disabled="readonly"
        v-on:change="updateTransactionData"
      >
      </v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <v-textarea
        :label="$t('_description')"
        solo
        auto-grow
        rows="3"
        v-model="description"
        :disabled="readonly"
        v-on:input="updateTransactionData"
      ></v-textarea>
    </v-flex>
    <v-flex xs12>
      <cost-basis
        :readonly="readonly"
        ref="cbComponent"
        :amounts="txn.amounts"
        :txn-type="txnType"
        v-model="costBasis"
        v-on:input="updateTransactionData"
        :txn="txn"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import _ from 'lodash';

import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      costBasis: null,
      description: '',
      category: '',
      contact: '',
    };
  },
  mounted() {
    this.populateForm();
    this.updateTransactionData();
  },
  methods: {
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.staking) {
          this.description = ad.staking.description;
          const foundCategory = this.categories.find((m) => m.id === ad.staking.categoryId);
          this.category = foundCategory;

          const foundContact = this.contacts.find((m) => m.id === ad.staking.contactId);
          this.contact = foundContact;
        } else {
          // throw new Error("Bad accounting details");
        }
      }
    },
    updateTransactionData() {
      const transactionData = {};
      transactionData.valid = true;
      const errors = [];

      if (!this.costBasis || !this.costBasis.valid) {
        transactionData.valid = false;
        errors.push('Please set a valid cost basis.');
      }

      if (!this.category || !this.category.id) {
        transactionData.valid = false;
        errors.push('Please select a valid transaction category.');
      }

      if (!this.contact || !this.contact.id) {
        transactionData.valid = false;
        errors.push('Please select a valid transaction contact.');
      }

      let stakingTxnType;
      if (this.txnType === 'receive') {
        stakingTxnType = 'ReturningStake';
      } else if (this.txnType === 'spend') {
        stakingTxnType = 'Staking';
      } else {
        throw new Error("Can't handle txn type");
      }

      transactionData.staking = {
        stakingTxnType,
        categoryId: this.category.id,
        contactId: this.contact.id,
        description: this.description,
        costBasis: this.costBasis,
      };

      console.log(transactionData);
      this.$emit('input', transactionData);
    },
  },
  computed: {
    filteredCategories() {
      return _.filter(this.categories, (m) => m.type === 'Asset');
    },
    filteredContacts() {
      return this.contacts;
      // if (this.txnType === "receive") {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Customer"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // } else {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Vendor"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // }
    },
  },
};
</script>
