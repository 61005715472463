<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-layout row wrap v-if="isLoading">
        <v-flex xs12 text-xs-center>
          <v-progress-circular indeterminate color="accent" :size="25" :width="3"></v-progress-circular>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12><hr /></v-flex>
        <v-flex xs6 pt-3>
          <v-layout row wrap>
            <v-flex xs12>
              <h4>Select {{ stakingType && stakingType.tokenActionLabel }}</h4>
            </v-flex>
            <v-flex xs12>
              <table width="100%" class="v-table">
                <tr>
                  <td></td>
                  <td>Asset</td>
                  <td>From</td>
                  <td>To</td>
                  <td>Amount</td>
                  <td>Action</td>
                </tr>
                <tr v-for="(log, index) in showStakingTxns" v-bind:key="`log-${index}`">
                  <td>
                    <v-checkbox v-model="log.checked" @change="updateTransactionData"></v-checkbox>
                  </td>
                  <td>{{ log.asset.symbol }}</td>
                  <td>{{ log.from.address.substr(0, 10) }}</td>
                  <td>{{ log.to.address.substr(0, 10) }}</td>
                  <td>
                    {{
                      bn(log.amount)
                        .div(10 ** log.decimals)
                        .toDecimalPlaces(2)
                        .toNumber()
                    }}
                  </td>
                  <td>{{ log.action }}</td>
                </tr>
                <tr v-if="otherStakingTxns.length > 0 && showOther === false" class="text-xs-center">
                  <td colspan="6">
                    <a href="#" @click="showOther = true" class="blue--text">Show Other Txns </a>
                  </td>
                </tr>
                <template v-if="showOther">
                  <tr v-for="(log, index) in otherStakingTxns" v-bind:key="`log-other-${index}`">
                    <td>
                      <v-checkbox v-model="log.checked" @change="updateTransactionData"></v-checkbox>
                    </td>
                    <td>{{ log.asset.symbol }}</td>
                    <td>{{ log.from.address.substr(0, 10) }}</td>
                    <td>{{ log.to.address.substr(0, 10) }}</td>
                    <td>
                      {{
                        bn(log.amount)
                          .div(10 ** log.decimals)
                          .toDecimalPlaces(2)
                          .toNumber()
                      }}
                    </td>
                    <td>{{ log.action }}</td>
                  </tr>
                </template>
              </table>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 pt-3>
          <v-flex xs12>
            <v-layout row wrap>
              <v-flex xs12>
                <h3>Staking Summary</h3>
              </v-flex>
              <v-flex xs3><b>DeFi Type</b></v-flex>
              <v-flex xs9>
                <v-select
                  ma-0
                  :items="stakingTypeOptions"
                  v-model="stakingType"
                  return-object
                  item-text="description"
                  item-value="dispId"
                  class="ma-0 pa-0"
                  style="width: 75%"
                ></v-select>
              </v-flex>
              <v-flex xs3><b>Detected Platform</b></v-flex>
              <v-flex xs9>{{ stakingType && stakingType.platformId }}</v-flex>
              <v-flex xs3 v-if="stakingType && stakingType.assets">
                <b>Composition</b>
              </v-flex>
              <v-flex xs9 v-if="stakingType && stakingType.assets">
                <ul class="list-straight">
                  <li v-for="(asset, ei) in stakingType.assets" v-bind:key="`a-${ei}`" style="list-style: none">
                    {{ asset.symbol }}
                    <span v-if="asset.poolPercentage">({{ asset.poolPercentage * 100 }}%)</span>
                  </li>
                </ul>
              </v-flex>
              <v-flex xs6 pt-3>
                <h5>Sent Coins</h5>
                <ul class="list-simple">
                  <li v-for="(sc, ei) in sentCoins" v-bind:key="`sc-${ei}`" style="list-style: none">
                    {{ bn(sc.displayValue.replaceAll(',', '')).abs().toDecimalPlaces(8).toNumber() }}
                    {{ sc.coin }}
                  </li>
                </ul>
              </v-flex>
              <v-flex xs6 pt-3>
                <h5>{{ stakingType && stakingType.tokenActionLabel }}</h5>
                <ul class="list-simple">
                  <li v-for="(sst, ei) in selectedStakingTxns" v-bind:key="`sst-${ei}`" style="list-style: none">
                    {{
                      bn(sst.amount)
                        .div(10 ** sst.decimals)
                        .toDecimalPlaces(8)
                        .toNumber()
                    }}
                    {{ sst.asset.symbol }}
                  </li>
                </ul>
              </v-flex>
              <v-flex
                xs12
                v-if="
                  stakingType && (stakingType.id === 'LiquidityPoolRemove' || stakingType.id === 'LiquidityPoolAdd')
                "
              >
                <v-layout row wrap>
                  <v-flex xs12>
                    <b>Total Staked Assets</b>
                  </v-flex>
                  <v-flex xs12 v-if="!totalHoldings && !stakingHistory">
                    <v-btn flat color="blue" @click="calculateTotalHoldings">Load Total Staked Details</v-btn>
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-btn flat small color="blue" @click="loadStakingHistory" :loading="this.stakingHistoryLoading"
                          >Reload Staked Details</v-btn
                        >
                      </v-flex>
                      <v-flex xs3 v-if="totalHoldings">
                        <h5>Percent of Pool</h5>
                      </v-flex>
                      <v-flex xs9 v-if="totalHoldings">
                        <span v-if="totalHoldings.percentOfPool">
                          {{ totalHoldings.percentOfPool.mul(100).toNumber() }}%
                        </span>
                      </v-flex>
                      <v-flex xs3>
                        <h5>User Pool Assets</h5>
                      </v-flex>
                      <v-flex xs9 v-if="totalHoldings">
                        <ul class="list-straight">
                          <li
                            v-for="(asset, ei) in totalHoldings.assets"
                            v-bind:key="`tha-${ei}`"
                            style="list-style: none"
                          >
                            {{ bn(asset.addressOwned).toDecimalPlaces(8).toString() }}
                            {{ asset.symbol }}
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                v-else-if="
                  stakingType &&
                  (stakingType.id === 'VaultWithdraw' ||
                    stakingType.id === 'VaultDeposit' ||
                    stakingType.id === 'Unstake' ||
                    stakingType.id === 'Borrow')
                "
              >
                <v-layout row wrap>
                  <v-flex xs12>
                    <b>Deposit History</b>
                  </v-flex>
                  <v-flex xs12 v-if="!stakingHistory">
                    <v-btn flat color="blue" @click="loadStakingHistory" :loading="this.stakingHistoryLoading"
                      >Load Staking History</v-btn
                    >
                  </v-flex>
                  <v-flex v-else xs12>
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-btn flat small color="blue" @click="loadStakingHistory" :loading="this.stakingHistoryLoading"
                          >Reload Staking History</v-btn
                        >
                      </v-flex>
                      <v-flex xs3>
                        <h5>Percent of Pool</h5>
                      </v-flex>
                      <v-flex xs9 v-if="totalHoldings">
                        <span v-if="totalHoldings.percentOfPool">
                          {{ totalHoldings.percentOfPool.mul(100).toNumber() }}%
                        </span>
                      </v-flex>
                      <v-flex xs3>
                        <h5>User Pool Assets</h5>
                      </v-flex>
                      <v-flex xs9 v-if="totalHoldings">
                        <ul class="list-straight">
                          <li
                            v-for="(asset, ei) in totalHoldings.assets"
                            v-bind:key="`tha-${ei}`"
                            style="list-style: none"
                          >
                            {{ bn(asset.addressOwned).toDecimalPlaces(8).toString() }}
                            {{ asset.symbol }}
                          </li>
                        </ul>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap v-if="fullCashFlowLines">
        <v-flex xs12>
          <h4>Revenue and Expense Recognition</h4>
        </v-flex>
        <v-flex xs12 v-for="(item, index) in fullCashFlowLines" v-bind:key="`cf-${index}`">
          <v-layout row wrap>
            <v-flex xs3 pa-1>
              <v-autocomplete
                :items="contacts"
                :label="$tc('_contact', 1)"
                v-model="item.contact"
                item-text="name"
                item-value="id"
                return-object
                :disabled="readonly"
                v-on:change="updateTransactionData"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs3 pa-1>
              <v-autocomplete
                :items="categories"
                :label="$tc('_category', 1)"
                v-model="item.category"
                item-text="name"
                item-value="id"
                return-object
                :disabled="readonly"
                v-on:change="updateTransactionData"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field label="Symbol" :disabled="readonly" v-model="item.symbol"></v-text-field>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field
                label="Amount in Crypto"
                :disabled="readonly"
                v-model="item.amount"
                v-on:change="updateTransactionData"
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field
                label="Fiat Value"
                :disabled="readonly"
                :value="calculateValueInFiat(item.symbol, item.amount)"
                readonly
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <!--    <v-flex xs12 class="text-xs-center"><a href="#" @click="toggleAdvanced">Advanced</a>-->
    <!--      <v-icon small v-if="!showAdvanced">fa-chevron-down</v-icon>-->
    <!--      <v-icon small v-else>fa-chevron-up</v-icon>-->
    <!--    </v-flex>-->
    <!--    <v-flex xs12 v-if="showAdvanced">-->
    <!--      <v-layout row wrap>-->
    <!--        <v-flex xs12>hello advanced</v-flex>-->
    <!--      </v-layout>-->
    <!--    </v-flex>-->
    <v-flex xs12>
      <h4>Fee</h4>
    </v-flex>
    <v-flex xs9>
      <v-autocomplete
        v-model="feeContactId"
        :disabled="readonly"
        :items="contacts"
        :label="'Fee Contact (Exchange or Network)'"
        item-text="name"
        item-value="id"
        persistent-hint
        v-on:change="updateTransactionData"
      >
      </v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <hr />
    </v-flex>
    <v-flex xs12>
      <cost-basis
        :dynamic-symbols="foundSymbols"
        :readonly="readonly"
        ref="cbComponent"
        :amounts="txn.amounts"
        :txn-type="txnType"
        v-model="costBasis"
        :include-fees="true"
        v-on:input="updateTransactionData"
        :txn="txn"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import * as axios from 'axios';
import gql from 'graphql-tag';
import * as math from 'mathjs';
import { string } from 'mathjs';
import * as _ from 'underscore';
import Web3 from 'web3';

import { isStrEmpty } from '@/utils/stringUtils';

import { baConfig } from '../../../../config';
import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      networkId: 'eth',
      costBasis: null,
      description: '',
      category: '',
      contact: '',
      stakedAddress: '0x0',
      isLoading: true,
      stakingType: null,
      stakingTypeOptions: [],
      tokenDetailsBySymbol: {},
      tokenDetailsByAddress: {},
      stakingPlatform: '',
      stakingTxns: [],
      pools: [],
      poolDetails: null,
      userAddress: '0x0',
      totalHoldings: null,
      feeContactId: null,
      stakingTarget: null,
      tokenActionLabel: '',
      stakingHistory: null,
      cashFlowLines: null,
      showOther: false,
      foundSymbols: [],
      stakingHistoryLoading: false,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.populateForm();
    this.setNetworkId();

    await this.getAddressDetails();

    this.updateTransactionData();

    this.isLoading = false;
  },
  methods: {
    async getRemoteAddressDetails(address) {
      const addressDetailsUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${address}`;
      console.log({ addressDetailsUrl });
      const resp = await axios.get(addressDetailsUrl);
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        this.isLoading = false;
        throw new Error('Problem getting address details');
      }
    },
    async getRemoteAddressTokenIdDetails(address, tokenId) {
      const resp = await axios.get(
        `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${address}?tokenId=${tokenId}`
      );
      if (resp && resp.status === 200) {
        return resp.data;
      } else {
        this.isLoading = false;
        throw new Error('Problem getting address details');
      }
    },
    async getLiquidityPoolHoldings(redemptionToken, block) {
      const poolHoldingsUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${this.userAddress}/balance?asset=${redemptionToken.address}&block=${block}`;
      console.log({ poolHoldingsUrl });
      const userPoolHoldings = await axios.get(poolHoldingsUrl);

      const poolSupplyUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${redemptionToken.address}/supply?block=${block}`;
      console.log({ poolSupplyUrl });
      const poolSupply = await axios.get(poolSupplyUrl);

      const uph = this.bn(userPoolHoldings.data.result);
      const ps = this.bn(poolSupply.data.result);
      const userPoolPercent = uph.div(ps);

      let holdingContract;
      const target = this.stakingType.target;
      if (target.holdingContract !== undefined) {
        holdingContract = target.holdingContract;
      } else {
        holdingContract = target;
      }

      const assets = [];
      for (const asset of target.assets) {
        const assetDecimals = asset.asset.decimals;
        let poolAssetOwnershipUrl;
        if (asset.asset.type === 'token-pointer') {
          poolAssetOwnershipUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${holdingContract.address}/balance?asset=${asset.asset.address}&block=${block}`;
        } else if (asset.asset.type === 'blockchain-currency-pointer') {
          poolAssetOwnershipUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${holdingContract.address}/balance?asset=${asset.asset.symbol}&block=${block}`;
        } else {
          this.isLoading = false;
          throw new Error('Unable to handle address type');
        }
        console.log({ poolAssetOwnershipUrl });
        const poolAssetOwnership = await axios.get(poolAssetOwnershipUrl);
        const pao = this.bn(poolAssetOwnership.data.result);
        console.log('PAO', poolAssetOwnership, pao);
        const addressOwned = pao.mul(userPoolPercent).div(10 ** assetDecimals);
        const a = {
          symbol: asset.symbol,
          decimals: asset.decimals,
          poolOwned: pao.div(10 ** assetDecimals).toNumber(),
          addressOwned: addressOwned.toString(),
          addressOwnedDisplay: addressOwned.toDecimalPlaces(3).toNumber(),
        };
        assets.push(a);
        this.foundSymbols.push(asset.symbol);
      }

      console.log('tots', userPoolHoldings, poolSupply, userPoolPercent);

      this.totalHoldings = {
        userPoolHoldings: userPoolHoldings.data.result,
        poolSupply: poolSupply.data.result,
        percentOfPool: userPoolPercent.toDecimalPlaces(5),
        assets,
      };
    },
    async getVaultHoldings(redemptionToken, block) {
      const vaultBalanceUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${redemptionToken.address}/vaultBalance?ownerAddress=${this.userAddress}&block=${block}&includeStakedHoldings=true`;
      console.log({ vaultBalanceUrl });
      const vaultHoldingsResp = await axios.get(vaultBalanceUrl, {
        validateStatus: () => true,
      });
      if (vaultHoldingsResp) {
        if (vaultHoldingsResp.status === 200) {
          const assets = [];
          for (const h of vaultHoldingsResp.data.balance) {
            const a = {
              symbol: h.ticker,
              addressOwned: h.value,
              addressOwnedDisplay: this.bn(h.value).toDecimalPlaces(3).toNumber(),
            };
            assets.push(a);
            this.foundSymbols.push(a.symbol);
          }

          // console.log("tots", userPoolHoldings, poolSupply, userPoolPercent);

          this.totalHoldings = {
            assets,
          };
        } else if (vaultHoldingsResp.status === 400) {
          this.totalHoldings = {
            assets: [],
          };
        } else {
          this.isLoading = false;
          throw new Error('problem getting address: ' + vaultHoldingsResp.status);
        }
      }
    },
    async getNftVaultHoldings(redemptionToken, block, tokenId) {
      const vaultBalanceUrl = `${baConfig.addressSvcUrl}/networks/${this.networkId}/addresses/${redemptionToken.address}/vaultBalance`;
      const vaultHoldingsResp = await axios.get(vaultBalanceUrl, {
        params: {
          ownerAddress: this.userAddress,
          block: block,
          includeStakedHoldings: 'true',
          tokenId: tokenId,
        },
        validateStatus: () => true,
      });
      if (vaultHoldingsResp) {
        if (vaultHoldingsResp.status === 200) {
          const assets = [];
          for (const h of vaultHoldingsResp.data.balance) {
            const a = {
              symbol: h.ticker,
              addressOwned: h.value,
              addressOwnedDisplay: this.bn(h.value).toDecimalPlaces(3).toNumber(),
            };
            assets.push(a);
            this.foundSymbols.push(a.symbol);
          }

          // console.log("tots", userPoolHoldings, poolSupply, userPoolPercent);

          this.totalHoldings = {
            assets,
          };
        } else if (vaultHoldingsResp.status === 400) {
          this.totalHoldings = {
            assets: [],
          };
        } else {
          this.isLoading = false;
          throw new Error('problem getting address: ' + vaultHoldingsResp.status);
        }
      }
    },
    async calculateTotalHoldings() {
      if (this.stakingType === undefined || this.stakingType.target === null) {
        return;
      }

      const tar = this.stakingType.target;

      console.log(tar);
      // Get the user's total pool holdings
      const block = this.txn.blocks[0];

      let redemptionToken;

      if (tar.redemptionToken !== undefined) {
        redemptionToken = tar.redemptionToken;
      } else {
        redemptionToken = tar;
      }

      if (tar.type === 'token-based-liquidity-pool') {
        await this.getLiquidityPoolHoldings(redemptionToken, block);
      } else if (tar.type === 'non-fungible-token-vault') {
        const tokenId = await this.getNftTokenId(tar.address);
        await this.getNftVaultHoldings(redemptionToken, block, tokenId);
      } else {
        await this.getVaultHoldings(redemptionToken, block);
      }

      this.updateTransactionData();
    },
    async loadStakingHistory() {
      this.stakingHistoryLoading = true;
      try {
        await this.calculateTotalHoldings();

        const targetId = this.stakingTarget.id;
        const holderId = `address.${this.txn.networkId}.${this.userAddress}`;

        const vars = {
          orgId: this.$store.state.currentOrg.id,
          targetId,
          holderId,
          beforeDateSEC: this.txn.created,
        };

        const res = await this.$apollo.query({
          query: gql`
            query getHistory($orgId: String!, $targetId: String!, $holderId: String!, $beforeDateSEC: Int) {
              staking {
                getHistory(orgId: $orgId, targetId: $targetId, holderId: $holderId, beforeDateSEC: $beforeDateSEC) {
                  totalDeposits {
                    value
                    symbol
                  }
                  totalWithdrawals {
                    value
                    symbol
                  }
                  totalRecognizedRevenue {
                    value
                    symbol
                  }
                }
              }
            }
          `,
          // Parameters
          variables: vars,
          fetchPolicy: 'network-only',
        });

        // // Create set of staked (withdrawn) txns
        const withdrawn = new Map();
        if (
          this.stakingType.id === 'VaultWithdraw' ||
          this.stakingType.id === 'Unstake' ||
          this.stakingType.id === 'Borrow'
        ) {
          for (const w of this.selectedStakingTxns) {
            const amount = this.bn(w.amount).div(10 ** w.decimals);
            withdrawn.set(w.asset.symbol, amount);
          }
        }

        // const deposited = new Map();
        if (
          this.stakingType.id === 'VaultDeposit' ||
          (this.stakingType.id === 'LiquidityPoolAdd' && this.stakingType.target.type === 'non-fungible-token-vault')
        ) {
          for (const w of this.selectedStakingTxns) {
            const amount = this.bn(w.amount).div(10 ** w.decimals);
            withdrawn.set(w.asset.symbol, amount.mul(-1));
          }
        }

        const data = res.data.staking.getHistory;
        this.stakingHistory = data;

        const totalDeposits = new Map();
        for (const d of data.totalDeposits) {
          totalDeposits.set(d.symbol, this.bn(d.value));
          this.foundSymbols.push(d.symbol);
        }

        const totalWithdrawals = new Map();
        for (const d of data.totalWithdrawals) {
          totalWithdrawals.set(d.symbol, this.bn(d.value));
          this.foundSymbols.push(d.symbol);
        }

        let totalHoldings;
        if (this.totalHoldings) {
          totalHoldings = new Map();
          for (const th of this.totalHoldings.assets) {
            if (totalHoldings.get(th.symbol) !== undefined) {
              const currValue = totalHoldings.get(th.symbol);
              totalHoldings.set(th.symbol, this.bn(th.addressOwned).add(currValue));
            } else {
              totalHoldings.set(th.symbol, this.bn(th.addressOwned));
              this.foundSymbols.push(th.symbol);
            }
          }
        }

        const totalRecognized = new Map();
        for (const th of data.totalRecognizedRevenue) {
          // const amount = this.bn(th.value).div(10 ** th.decimals);
          totalRecognized.set(th.symbol, this.bn(th.value));
          this.foundSymbols.push(th.symbol);
        }

        // foreach item in withdrawn
        if (this.stakingType && this.stakingType.id === 'Borrow') {
          const cashFlowLines = [];
          for (const w of withdrawn.entries()) {
            const previouslyBorrowed = totalWithdrawals.get(w[0]) ? totalWithdrawals.get(w[0]) : this.bn(0);
            let holding = this.bn(0);
            if (totalHoldings) {
              holding = totalHoldings.get(w[0]) ? totalHoldings.get(w[0]) : this.bn(0);
            }

            const currTotal = holding.plus(w[1]);
            const currInterest = currTotal.plus(previouslyBorrowed);
            if (!currInterest.eq(0)) {
              cashFlowLines.push({
                symbol: w[0],
                amount: currInterest.toString(), // Need decimal here
              });
            }
          }
          this.cashFlowLines = cashFlowLines;
        } else {
          const cashFlowLines = [];
          console.log('Withdrawn: ' + withdrawn.entries());
          // Looking at each of the withdrawn entries (ie deposits to our account)
          // Lets figure out what is net new.
          // BTW, this won't handle a loss in a vault, but it needs to
          const searchAssets = new Set();
          for (const t of totalHoldings.entries()) {
            searchAssets.add(t[0]);
          }
          for (const t of withdrawn.entries()) {
            searchAssets.add(t[0]);
          }

          for (const t of searchAssets.values()) {
            // for (const w of withdrawn.entries()) {
            // How much have we deposited at this point (historically, not including this txn)
            const deposited = totalDeposits.get(t) ? totalDeposits.get(t) : this.bn(0);

            // How much have we withdrawn historically?
            const prevWithdrawn = totalWithdrawals.get(t) ? totalWithdrawals.get(t) : this.bn(0);

            // What have we recognized historically? I actually don't think we want to incoporate this...
            const prevRec = totalRecognized.get(t) ? totalRecognized.get(t) : this.bn(0);

            // Deposits minus withdrawals = holdings before this txn
            // This is the amount held on the staking contract
            const prevTotal = deposited.minus(prevWithdrawn);
            console.log('Prev Total: ' + prevTotal);

            // Our new holdings after the withdrawal
            let currAdjustment = withdrawn.get(t);
            if (currAdjustment === undefined) {
              currAdjustment = this.bn(0);
            }

            // const newTotal = prevTotal.plus(currAdjustment);
            // console.log("New Total: " + newTotal);

            let holding = this.bn(0);
            if (totalHoldings) {
              holding = totalHoldings.get(t) ? totalHoldings.get(t) : this.bn(0);
            }

            const currTotal = holding.plus(currAdjustment);
            console.log('currTotal: ' + currTotal);

            const oldProfit = currTotal.minus(deposited).plus(prevWithdrawn).minus(prevRec);
            console.log('oldProfit: ' + oldProfit);

            // const currProfit = newTotal.minus(prevTotal); // prevTotal.minus(currTotal);
            // console.log("currProfit: " + currProfit);

            if (!oldProfit.eq(0)) {
              cashFlowLines.push({
                symbol: t,
                amount: oldProfit.toString(), // Need decimal here
              });
            }
          }
          this.cashFlowLines = cashFlowLines;
        }

        this.updateTransactionData();
      } catch (e) {
        console.log('Error loading staking history: ' + e);
      } finally {
        this.stakingHistoryLoading = false;
      }
    },
    // This uses a web3 call to pull the transaction receipt from
    // a txHash and then determines the tokenId in two scenarios.
    async getNftTokenId(targetAddress) {
      const txnHash = this.txn.id;
      const web3 = new Web3(baConfig.ethRpcUrl);
      const tokenId = await web3.eth.getTransactionReceipt(txnHash).then((data) => {
        const logs = data.logs;
        for (let i = 0; i < logs.length; i++) {
          const log = logs[i];
          if (string(log.address).toLowerCase() === string(targetAddress).toLowerCase()) {
            const nftTransferTokenId = web3.utils.hexToNumber(log.topics[3]);
            const nftChangeTokenId = web3.utils.hexToNumber(log.topics[1]);
            if (nftTransferTokenId === undefined) {
              return nftChangeTokenId;
            } else {
              return nftTransferTokenId;
            }
          }
        }
      });
      return tokenId;
    },
    async getAddressDetails() {
      const interactionLookupPromises = this.interactionAddresses.map(async (address) => {
        return this.getRemoteAddressDetails(address);
      });

      const tokenLookupPromises = this.tokenAddresses.map(async (address) => {
        return this.getRemoteAddressDetails(address);
      });

      const coinLookupPromises = this.coinAddresses.map(async (address) => {
        return this.getRemoteAddressDetails(address);
      });

      const coinLookups = await Promise.all(coinLookupPromises);
      const interactionLookups = await Promise.all(interactionLookupPromises);
      const tokenLookups = await Promise.all(tokenLookupPromises);
      const unsortedLookups = tokenLookups.concat(interactionLookups).concat(coinLookups);
      const topPriLookups = unsortedLookups.filter((m) => m.item.type === 'non-fungible-token-vault');
      const highPriLookups = unsortedLookups.filter((m) => m.item.type === 'debt-token');
      const lowPriLookups = unsortedLookups.filter((m) => m.item.type !== 'debt-token');
      const lookups = topPriLookups.concat(highPriLookups).concat(lowPriLookups);
      const bySymbol = {};
      const byAddress = {};
      for (const l of lookups) {
        if (l.meta && l.meta.symbol) {
          bySymbol[l.meta.symbol] = l;
          byAddress[l.item.address.toLowerCase()] = l;
        }
      }

      this.tokenDetailsBySymbol = bySymbol;
      this.tokenDetailsByAddress = byAddress;

      let isVault = false;
      let isDebt = false;
      let isNftVault = false;
      const allIn = this.txn.amounts.find((v) => this.bn(v.value).lt(0)) === undefined;
      const allOut = this.txn.amounts.find((v) => this.bn(v.value).gt(0)) === undefined;

      let target = null;
      let direction = 'out';
      function setOrDef(dir) {
        direction = dir;
        if (direction === undefined) {
          direction = dir;
        } else if (direction !== dir) {
          direction = 'unknown';
        }
      }
      // let isRecognizedPlatformToken = false;
      for (const fl of lookups) {
        if (
          fl &&
          (fl.item.type === 'token-liquidity-pool' ||
            fl.item.type === 'token-based-vault' ||
            fl.item.type === 'pool-redemption-token' ||
            fl.item.type === 'pool' ||
            fl.item.type === 'vault' ||
            fl.item.type === 'debt-token' ||
            fl.item.type === 'non-fungible-token-vault')
        ) {
          this.stakingPlatform = fl.item.platformId;
          isVault =
            fl.item.type === 'token-based-vault' ||
            fl.item.type === 'vault' ||
            fl.item.type === 'pool-redemption-token';

          isDebt = fl.item.type === 'debt-token';
          isNftVault = fl.item.type === 'non-fungible-token-vault';

          target = fl;
          break;
        }
      }

      const contracts = this.txn.to;

      const stakingTokensType = (target) => {
        return {
          dispId: `stake.${target.address}`,
          id: 'Stake',
          direction: 'out',
          description:
            'Staking Tokens to ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') +
            target.address,
          target,
          tokenActionLabel: 'Staked Tokens',
        };
      };

      const vaultWithdrawalType = (target) => {
        return {
          dispId: `vault-withdraw.${target.address}`,
          id: 'VaultWithdraw',
          direction: 'in',
          description:
            'Vault Withdrawal from ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') +
            target.address,
          tokenActionLabel: 'Withdrawn Tokens',
          target,
          platformId: target.platformId,
          assets: target.assets,
        };
      };

      const vaultDepositType = (target) => {
        return {
          dispId: `vault-deposit.${target.address}`,
          id: 'VaultDeposit',
          direction: 'out',
          description:
            'Vault Deposit to ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') +
            target.address,
          target,
          tokenActionLabel: 'Deposited Tokens',
          platformId: target.platformId,
          assets: target.assets,
        };
      };

      const repaymentType = (target) => {
        return {
          id: 'Repayment',
          dispId: `repayment.${target.address}`,
          direction: 'out',
          description:
            'Repayment to ' + (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') + target.address,
          target,
          tokenActionLabel: 'Repaid Tokens',
        };
      };

      const liquidityRemoveType = (target) => {
        return {
          id: 'LiquidityPoolRemove',
          dispId: `liquidity-pool-remove.${target.address}`,
          direction: 'in',
          description:
            'Removing from ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' ' : '') +
            'Liquidity Pool at ' +
            target.address,
          target,
          tokenActionLabel: 'Withdrawn Tokens',
          platformId: target.platformId,
          assets: target.assets,
        };
      };

      const borrowType = (target) => {
        return {
          id: 'Borrow',
          dispId: `borrow.${target.address}`,
          direction: 'in',
          description:
            'Borrow from ' + (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') + target.address,
          target,
          tokenActionLabel: 'Borrowed Tokens',
        };
      };

      const unstakingTokensType = (target) => {
        return {
          dispId: `unstake.${target.address}`,
          id: 'Unstake',
          direction: 'in',
          description:
            'Unstaking Tokens from ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' at ' : '') +
            target.address,
          target,
          tokenActionLabel: 'Unstaked Tokens',
        };
      };

      const liquidityPoolAddType = (target) => {
        return {
          id: 'LiquidityPoolAdd',
          dispId: `liquidity-pool-add.${target.address}`,
          direction: 'out',
          description:
            'Depositing to ' +
            (target.friendlyName !== undefined ? target.friendlyName + ' ' : '') +
            'Liquidity Pool at ' +
            target.address,
          tokenActionLabel: 'Deposited Tokens',
          target,
          platformId: target.platformId,
          assets: target.assets,
        };
      };

      if (isNftVault) {
        const tokenId = await this.getNftTokenId(target.item.address);
        const nftAddress = target.item.address;
        const resp = await this.getRemoteAddressTokenIdDetails(nftAddress, tokenId);
        const st = stakingTokensType(resp.item);
        const ust = unstakingTokensType(resp.item);
        const vdt = vaultDepositType(resp.item);
        const lad = liquidityPoolAddType(resp.item);
        const lrm = liquidityRemoveType(resp.item);
        const vwt = vaultWithdrawalType(resp.item);
        this.stakingType = lad;
        this.stakingTypeOptions.push(st, ust, vdt, lad, lrm, vwt);
      }
      // So, what I'm doing here is looking at each value change and seeing if the token moving is a known token.
      // This has some weird side effect, including that movement of something like eth gets totally ignored

      // What we really want to do is look at the actual interaction contracts - do we even want to look at them
      // in terms of those that have value end up in them?

      // In other words, could I sum the value movements to just see where value ends,
      // filter that by the user address... and bob's your uncle?

      for (const v of this.txn.amounts) {
        const fa = this.tokenDetailsBySymbol[v.coin];
        let isRecognizedPlatformToken = false;
        if (
          fa &&
          fa.item &&
          (fa.item.type === 'token-liquidity-pool' ||
            fa.item.type === 'token-based-vault' ||
            fa.item.type === 'pool-redemption-token' ||
            fa.item.type === 'pool' ||
            fa.item.type === 'vault' ||
            fa.item.type === 'debt-token' ||
            fa.item.type === 'address-based-vault' ||
            fa.item.type === 'non-fungible-token-vault')
        ) {
          isRecognizedPlatformToken = true;
        }

        if (this.bn(v.value).lt(0)) {
          if (isRecognizedPlatformToken || !this.stakingType) {
            if (this.txn.amounts.length === 1 || allOut) {
              const s = new Set();
              for (const tl of this.txn.txnLogs) {
                if (!s.has(tl.to.address)) {
                  const st = stakingTokensType(tl.to);
                  const vdt = vaultDepositType(tl.to);
                  const rt = repaymentType(tl.to);
                  this.stakingTypeOptions.push(st, vdt, rt);
                  s.add(tl.to.address);
                }
              }
            } else if (isVault) {
              let target;
              if (fa && fa.item.type === 'pool-redemption-token') {
                const resp = await this.getRemoteAddressDetails(fa.item.pool.address);
                target = resp.item;
              } else if (fa) {
                target = fa.item;
              }

              if (target) {
                setOrDef('in');
                const st = stakingTokensType(target);
                const ust = unstakingTokensType(target);
                const vwt = vaultWithdrawalType(target);
                this.stakingType = vwt;
                this.stakingTypeOptions.push(st, ust, vwt);
              }
            } else if (isDebt) {
              let target;
              if (fa.item.type === 'pool-redemption-token') {
                const resp = await this.getRemoteAddressDetails(fa.item.pool.address);
                target = resp.item;
              } else {
                target = fa.item;
              }
              const rt = repaymentType(target);
              this.stakingType = rt;
              this.stakingTypeOptions.push(rt);
              setOrDef('in');
            } else if (fa && fa.item) {
              const lt = liquidityRemoveType(fa.item);
              const ust = unstakingTokensType(fa.item);
              this.stakingType = lt;
              this.stakingTypeOptions.push(lt, ust);
              setOrDef('in');
            }
          }

          this.userAddress = this.txn.wallet[0].addresses[0]
            ? this.txn.wallet[0].addresses[0]
            : this.txn.from[0].address.toLowerCase();
        } else {
          if (isRecognizedPlatformToken || !this.stakingType) {
            if (isVault) {
              setOrDef('out');
              let target;
              if (fa && fa.item.type === 'pool-redemption-token') {
                const resp = await this.getRemoteAddressDetails(fa.item.pool.address);
                target = resp.item;
              } else if (fa) {
                target = fa.item;
              }

              if (target) {
                const vdt = vaultDepositType(target);
                const utt = unstakingTokensType(target);
                this.stakingType = vdt;
                this.stakingTypeOptions.push(vdt, utt);
              }
            } else if (isDebt) {
              let target;
              if (fa.item.type === 'pool-redemption-token') {
                const resp = await this.getRemoteAddressDetails(fa.item.pool.address);
                target = resp.item;
              } else {
                target = fa.item;
              }
              if (target.address !== '0x0000000000000000000000000000000000000000') {
                const bt = borrowType(target);
                this.stakingType = bt;
                this.stakingTypeOptions.push(bt);
              }

              for (const contract of contracts) {
                const resp = await this.getRemoteAddressDetails(contract.address);
                if (resp.item) {
                  const cb = borrowType(resp.item);
                  this.stakingTypeOptions.push(cb);
                  if (this.stakingType === undefined) {
                    this.stakingType = cb;
                  }
                }
              }

              setOrDef('out');
            } else if (this.txn.amounts.length === 1 || allIn) {
              const s = new Set();
              for (const tl of this.txn.txnLogs) {
                if (!s.has(tl.from.address)) {
                  if (tl.from.address !== '0x0000000000000000000000000000000000000000') {
                    const utt = unstakingTokensType(tl.from);
                    const bt = borrowType(tl.from);

                    this.stakingType = utt;
                    this.stakingTypeOptions.push(utt, bt);
                    this.stakingTypeOptions.push(utt);
                    s.add(tl.from.address);
                  }
                }
              }

              for (const contract of contracts) {
                const resp = await this.getRemoteAddressDetails(contract.address);
                if (resp.item) {
                  const cb = borrowType(resp.item);
                  this.stakingTypeOptions.push(cb);
                  if (this.stakingType === undefined || this.stakingType === null) {
                    this.stakingType = cb;
                  }
                }
              }

              setOrDef('in');
            } else if (fa && fa.item) {
              if (fa.item.type === 'address-based-vault') {
                const vdt = vaultDepositType(fa.item);
                this.stakingType = vdt;
                this.stakingTypeOptions.push(vdt);
              } else {
                const lp = liquidityPoolAddType(fa.item);
                const st = stakingTokensType(fa.item);
                const vdt = vaultDepositType(fa.item);
                this.stakingType = lp;
                this.stakingTypeOptions.push(lp);
                this.stakingTypeOptions.push(st);
                this.stakingTypeOptions.push(vdt);
              }

              setOrDef('out');
            }
          }
        }
      }
      if (!direction) {
        direction = 'out'; // Default to out I guess
      }

      if (this.txn.from.length > 0) {
        const st1 = stakingTokensType(this.txn.from[0]);
        const ust1 = unstakingTokensType(this.txn.from[0]);
        this.stakingTypeOptions.push(...[st1, ust1]);
      }

      if (this.txn.to.length > 0) {
        const st2 = stakingTokensType(this.txn.to[0]);
        const ust2 = unstakingTokensType(this.txn.to[0]);
        this.stakingTypeOptions.push(...[st2, ust2]);
      }

      this.userAddress = this.txn.wallet[0].addresses[0] ? this.txn.wallet[0].addresses[0] : this.txn.from[0].address;

      // const hitSyms = new Set();
      const items = [];

      // const sentItems = [];
      // const receivedItems = [];
      // const stakedItems = [];
      // const unstakedItems = [];

      const logs = [];
      logs.push(...this.txn.txnLogs);
      logs.reverse();
      for (const tl of logs) {
        console.log(tl);
        // First, just setup some details on the coins
        if (tl.asset.type === 'blockchain-coin') {
          if (tl.asset.networkId === 'eth') {
            this.$set(tl, 'decimals', 18);
            this.$set(tl.asset, 'symbol', 'ETH');
          }
        } else {
          const xfrToken = this.tokenDetailsByAddress[tl.asset.address.toLowerCase()];
          if (xfrToken.item.token && xfrToken.item.token.decimals !== undefined) {
            this.$set(tl, 'decimals', xfrToken.item.token.decimals);
          } else if (xfrToken.item.decimals !== undefined) {
            this.$set(tl, 'decimals', xfrToken.item.decimals);
          } else {
            console.log('Problem getting decimals for token');
          }
        }

        let fromLookup;
        if (tl.from && tl.from.address) {
          fromLookup = this.tokenDetailsByAddress[tl.from.address];
        }

        let toLookup;
        if (tl.to && tl.to.address) {
          toLookup = this.tokenDetailsByAddress[tl.to.address];
        }

        if (toLookup && toLookup.item && toLookup.item.type === 'address-based-vault') {
          const vdt = vaultDepositType(toLookup.item);
          const vwt = vaultWithdrawalType(toLookup.item);
          this.stakingTypeOptions.splice(0, 0, vwt);
          this.stakingTypeOptions.splice(0, 0, vdt);
          if (!this.stakingType) {
            this.stakingType = direction === 'out' ? vdt : vwt;
          }
        } else if (fromLookup && fromLookup.item && fromLookup.item.type === 'address-based-vault') {
          const vdt = vaultDepositType(fromLookup.item);
          const vwt = vaultWithdrawalType(fromLookup.item);
          this.stakingTypeOptions.splice(0, 0, vwt);
          this.stakingTypeOptions.splice(0, 0, vdt);
          this.stakingType = direction === 'in' ? vwt : vdt;
        } else if (
          fromLookup &&
          fromLookup.item &&
          fromLookup.item.type === 'token-based-vault' &&
          (fromLookup.item.generatingRuleId === 'compound-ether' ||
            fromLookup.item.generatingRuleId === 'compound' ||
            fromLookup.item.generatingRuleId === 'compound-v2')
        ) {
          const bt = borrowType(fromLookup.item);
          const rt = repaymentType(fromLookup.item);
          this.stakingTypeOptions.splice(0, 0, bt);
          if (!this.stakingType) {
            this.stakingType = direction === 'in' ? rt : bt;
          }
        } else if (
          toLookup &&
          toLookup.item &&
          toLookup.item.type === 'token-based-vault' &&
          (toLookup.item.generatingRuleId === 'compound-ether' ||
            toLookup.item.generatingRuleId === 'compound' ||
            toLookup.item.generatingRuleId === 'compound-v2')
        ) {
          const rt = repaymentType(toLookup.item);
          this.stakingTypeOptions.splice(0, 0, rt);
          if (!this.stakingType) {
            this.stakingType = rt;
          }
        }
        const isUnCategorized = this.txn.categorizationStatus === 'Uncategorized';
        if (!isUnCategorized) {
          const ad = this.txn?.accountingDetails?.[0];
          const withdrawnAssets = (ad.assetStaking.withdrawnAssets ?? []).map((asset) => asset.symbol);
          this.$set(tl, 'checked', tl.asset?.symbol && withdrawnAssets.includes(tl.asset.symbol));
        }
        // If it's out and it's from me, that's a sent coin
        if (direction === 'out' && tl.from.address.toLowerCase() === this.userAddress.toLowerCase()) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', true);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'sent');
          items.push(tl);
        } else if (direction === 'out' && tl.to.address.toLowerCase() === this.userAddress.toLowerCase()) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', false);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'received');
          items.push(tl);
        } else if (
          // If it's out and it hits the target, staked
          direction === 'out' &&
          target &&
          tl.to.address.toLowerCase() === target.item.address.toLowerCase()
        ) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', false);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'staked');
          items.push(tl);
        } else if (direction === 'in' && tl.to.address.toLowerCase() === this.userAddress.toLowerCase()) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', true);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'received');
          items.push(tl);
        } else if (
          // If it's out and it hits the target, staked
          direction === 'in' &&
          target &&
          tl.from.address.toLowerCase() === this.userAddress.toLowerCase()
        ) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', false);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'sent');
          items.push(tl);
        } else if (
          // If it's out and it hits the target, staked
          direction === 'out' &&
          target &&
          tl.from.address.toLowerCase() === target.item.address.toLowerCase()
        ) {
          if (isUnCategorized) {
            this.$set(tl, 'checked', true);
          }
          this.$set(tl, 'showDefault', true);
          this.$set(tl, 'action', 'received');
          items.push(tl);
        } else {
          // otherwise it's not relevant to the txn, just ignore it
          this.$set(tl, 'showDefault', false);
          this.$set(tl, 'action', 'ignore');
          items.push(tl);
        }
      }

      console.log('StakingOptions: ', this.stakingTypeOptions);
      console.log('Staking Type: ', this.stakingType);

      {
        items.reverse();
        this.stakingTxns = items;

        let targetId;
        if (this.stakingType && this.stakingType.target) {
          const target = this.stakingType.target;
          targetId = 'address.' + target.networkId + '.' + target.address;
          this.stakingTarget = {
            networkId: target.networkId,
            address: target.address,
            id: targetId,
          };
        } else {
          targetId = 'address.' + this.txn.networkId + '.' + this.txn.to[0].address;
          this.stakingTarget = {
            networkId: this.txn.networkId,
            address: this.txn.to[0].address,
            id: targetId,
          };
        }
      }
    },
    calculateValueInFiat(coin, value) {
      if (!coin || !value) {
        return 0;
      }

      if (this.costBasis) {
        const er = this.$refs.cbComponent.exchangeRateForCurrency(coin);
        const val = er * value;
        const bn = this.bn(val);
        return bn.toDecimalPlaces(2).toNumber();
      } else {
        return 0;
      }
    },
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.assetStaking) {
          // Do stuff
          const ast = ad.assetStaking;

          if (ast.fee && ast.fee.feeContactId) {
            this.feeContactId = ast.fee.feeContactId;
          }

          if (ast.poolDetails) {
            const assets = [];
            if (ast.totalHoldings) {
              for (const th of ast.totalHoldings) {
                assets.push({
                  addressOwned: th.value,
                  symbol: th.symbol,
                });
              }
            }

            this.totalHoldings = {
              userPoolHoldings: ast.poolDetails.addressPoolTokens,
              poolSupply: ast.poolDetails.totalPoolTokens,
              percentOfPool: this.bn(ast.poolDetails.percentOfPool),
              assets,
            };
          }

          if (ast.recognizedRevenue) {
            this.cashFlowLines = [];
            for (const r of ast.recognizedRevenue) {
              if (r.lines.length > 1) {
                this.isLoading = false;
                throw new Error("Can't handle multple lines");
              }

              this.cashFlowLines.push({
                contact: r.contactId,
                category: r.lines[0].categoryId,
                symbol: r.lines[0].coin,
                amount: r.lines[0].coinAmount, // Need decimal here
              });
            }
          }

          if (ast.stakingTarget) {
            // do stuff
          }

          // const foundCategory = this.categories.find(
          //   m => m.id === ad.staking.categoryId
          // );
          // this.category = foundCategory;
          //
          // const foundContact = this.contacts.find(
          //   m => m.id === ad.staking.contactId
          // );
          // this.contact = foundContact;
        } else {
          // throw new Error("Bad accounting details");
        }
      } else {
        const org = this.$store.state.currentOrg;
        if (org.accountingConfig && org.accountingConfig.networkContactIds) {
          const networkContactId = org.accountingConfig.networkContactIds[this.txn.networkId];
          if (networkContactId) {
            this.feeContactId = networkContactId;
          }
        }
      }
    },
    updateTransactionData() {
      const transactionData = {};
      transactionData.valid = true;
      const errors = [];

      const baseCurrency = this.$store.state.currentOrg.baseCurrency;

      // if (!this.costBasis || !this.costBasis.valid) {
      //   transactionData.valid = false;
      //   errors.push("Please set a valid cost basis.");
      // }

      let stakedAssets = [];
      let withdrawnAssets = [];
      if (this.selectedStakingTxns && this.selectedStakingTxns.length > 0) {
        const mappedAssets = this.selectedStakingTxns.map((m) => {
          return {
            value: this.bn(m.amount)
              .div(10 ** m.decimals)
              .toString(),
            symbol: m.asset.symbol,
          };
        });
        if (
          this.stakingType.id === 'VaultWithdraw' ||
          this.stakingType.id === 'LiquidityPoolRemove' ||
          this.stakingType.id === 'Unstake' ||
          this.stakingType.id === 'Borrow'
        ) {
          withdrawnAssets = mappedAssets;
        } else {
          stakedAssets = mappedAssets;
        }
      } else {
        transactionData.valid = false;
        errors.push('No staking txns selected');
      }

      let totalHoldings, poolDetails;
      if (this.totalHoldings) {
        totalHoldings = this.totalHoldings.assets.map((m) => {
          return {
            value: m.addressOwned,
            symbol: m.symbol,
          };
        });
        if (this.totalHoldings.userPoolHoldings && this.totalHoldings.poolSupply && this.totalHoldings.percentOfPool) {
          poolDetails = {
            addressPoolTokens: this.totalHoldings.userPoolHoldings,
            totalPoolTokens: this.totalHoldings.poolSupply,
            percentOfPool:
              this.totalHoldings.percentOfPool === undefined ? undefined : this.totalHoldings.percentOfPool.toString(),
          };
        }
      } else if (
        this.stakingType !== null &&
        (this.stakingType.id === 'Stake' ||
          this.stakingType.id === 'Unstake' ||
          this.stakingType.id === 'Borrow' ||
          this.stakingType === 'Repayment')
      ) {
        // No op, weird
      } else {
        transactionData.valid = false;
        errors.push('Total Holdings must be loaded and valid');
      }

      const exchangeRates = [];
      for (const er of this.$refs.cbComponent.exchangeRates) {
        exchangeRates.push({
          coin: er.coin,
          unit: er.unit,
          fiat: er.fiat,
          rate: er.rate,
          source: er.source,
        });
      }

      transactionData.assetStaking = {
        stakedAssets,
        withdrawnAssets,
        totalHoldings,
        poolDetails,
        exchangeRates,
        // block,
        // holdingAddress,
        // stakingTarget
      };

      if (
        this.stakingType &&
        this.stakingType.target &&
        (this.stakingType.id === 'Stake' || this.stakingType.id === 'Unstake')
      ) {
        const target = this.stakingType.target;
        transactionData.assetStaking.stakingTarget = {
          networkId: target.networkId,
          address: target.address,
        };

        if (transactionData.stakedAssets && transactionData.stakedAssets.length === 1) {
          const s = transactionData.stakedAssets[0];
          transactionData.assetStaking.stakedAsset = {
            networkId: s.networkId,
            address: s.address,
          };
        }
      } else if (this.stakingType && this.stakingType.target) {
        const target = this.stakingType.target;
        transactionData.assetStaking.stakingTarget = {
          networkId: target.networkId,
          address: target.address,
        };
      } else {
        transactionData.valid = false;
        errors.push('Pool details are missing');
      }

      if (this.costBasis && this.costBasis.fees && this.costBasis.fees.length > 0) {
        const feeItems = this.costBasis.fees.map((m) => {
          return {
            amount: m.amount,
            costBasis: m.costBasis,
          };
        });

        transactionData.assetStaking.fee = {
          feeItems,
          feeContactId: this.feeContactId,
        };

        if (feeItems.length > 0 && this.feeContactId === null) {
          transactionData.valid = false;
        }
      }

      if (this.txn && this.txn.blocks.length > 0) {
        transactionData.assetStaking.block = {
          networkId: this.txn.networkId,
          blockId: this.txn.blocks[0],
        };
      } else {
        transactionData.valid = false;
      }

      if (this.stakingType) {
        transactionData.assetStaking.type = this.stakingType.id;
      } else {
        transactionData.valid = false;
      }

      if (this.userAddress) {
        transactionData.assetStaking.holdingAddress = {
          networkId: this.txn.networkId,
          address: this.userAddress,
        };
      } else {
        transactionData.valid = false;
      }

      if (this.fullCashFlowLines && this.fullCashFlowLines.length > 0) {
        const recognizedRevenue = [];
        for (const l of this.fullCashFlowLines) {
          if (isStrEmpty(l.symbol) || isStrEmpty(l.amount)) {
            transactionData.valid = false;
            break;
          }

          if (!l.contact) {
            transactionData.valid = false;
            break;
          }

          if (!l.category) {
            transactionData.valid = false;
            break;
          }

          const tl = {
            categoryId: l.category.id,
            sourceTicker: l.symbol,
            sourceAmount: l.amount.toString(),
            fiat: baseCurrency,
            fiatAmount: this.calculateValueInFiat(l.symbol, l.amount).toString(),
          };

          const split = {
            contactId: l.contact.id,
            transactionType: this.txnAmountTotal > 0 ? 'Income' : 'Expense',
            lines: [tl],
          };
          recognizedRevenue.push(split);
        }
        transactionData.assetStaking.recognizedRevenue = recognizedRevenue;
      }

      console.log(transactionData);
      this.$emit('input', transactionData);
    },
    setNetworkId() {
      console.log('TXN: ', this.txn);
      console.log('TXN LOGS:', this.txn.txnLogs);

      if (this.txn.id.includes('.')) {
        const [prefix] = this.txn.id.split('.');
        this.networkId = prefix.length > 0 ? prefix.toLowerCase() : 'eth';
      }
    },
  },
  watch: {
    stakingType() {
      const target = this.stakingType.target;
      const targetId = 'address.' + target.networkId + '.' + target.address;
      this.stakingTarget = {
        networkId: target.networkId,
        address: target.address,
        id: targetId,
      };
    },
  },
  computed: {
    fullCashFlowLines() {
      const calculatedCashFlowLines = this.cashFlowLines;
      const selectedReceiveCashFlowLines = this.receivedTokens.map((m) => {
        const amount = math
          .bignumber(m.amount)
          .div(10 ** m.decimals)
          .toNumber();

        return {
          symbol: m.asset.symbol,
          amount,
        };
      });

      const selectedSentCashFlowLines = this.sentTokens.map((m) => {
        const amount = math
          .bignumber(m.amount)
          .div(10 ** m.decimals)
          .mul(-1)
          .toNumber();

        return {
          symbol: m.asset.symbol,
          amount,
        };
      });
      const ret = [];
      if (calculatedCashFlowLines && calculatedCashFlowLines.length > 0) {
        ret.push(...calculatedCashFlowLines);
      }

      if (selectedReceiveCashFlowLines.length > 0) {
        ret.push(...selectedReceiveCashFlowLines);
      }

      if (selectedSentCashFlowLines.length > 0) {
        ret.push(...selectedSentCashFlowLines);
      }

      return ret;
    },
    selectedStakingTxns() {
      if (this.stakingType) {
        const dir = this.stakingType.direction;
        return this.stakingTxns.filter((m) => {
          if (dir === 'out') {
            return m.checked && m.action !== 'received';
          } else {
            return m.checked && m.action !== 'sent';
          }
        });
      }
      return undefined;
    },
    sentCoins() {
      return this.txn.amounts.filter((m) => this.bn(m.value).lt(0));
    },
    showStakingTxns() {
      return this.stakingTxns.filter((m) => m.showDefault === true);
    },
    otherStakingTxns() {
      return this.stakingTxns.filter((m) => m.showDefault === false);
    },
    receivedTokens() {
      if (this.stakingType && this.stakingType.direction === 'out') {
        return this.stakingTxns.filter((m) => m.checked === true && m.action === 'received');
      } else {
        return [];
      }
    },
    sentTokens() {
      if (this.stakingType && this.stakingType.direction === 'in') {
        return this.stakingTxns.filter((m) => m.checked === true && m.action === 'sent');
      } else {
        return [];
      }
    },
    tokenAddresses() {
      const addresses = new Set();
      for (const txn of this.txn.txnLogs) {
        if (txn.asset.address && txn.asset.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.asset.address);
        }
      }
      return Array.from(addresses.values());
    },
    interactionAddresses() {
      const addresses = new Set();
      for (const txn of this.txn.txnLogs) {
        if (txn.from.address && txn.from.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.from.address);
        }

        if (txn.to.address && txn.to.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.to.address);
        }
      }

      if (this.txn.to) {
        for (const to of this.txn.to) {
          if (to.address && to.address !== '0x0000000000000000000000000000000000000000') {
            addresses.add(to.address);
          }
        }
      }

      return Array.from(addresses.values());
    },
    coinAddresses() {
      // This is a temporary measure to work around incorrectly cached transactions
      // TODO: Remove this once the cache is fixed
      const addresses = new Set();
      if (this.txn.txnLines?.length) {
        for (const amount of this.txn.txnLines) {
          if (amount.asset.toString().toLowerCase().includes('uniswap')) {
            addresses.add('0xc36442b4a4522e871399cd717abdd847ab11fe88');
          }
        }
      } else {
        for (const amount of this.txn.fullAmountSet) {
          if (amount.coin.toString().toLowerCase().includes('uniswap')) {
            addresses.add('0xc36442b4a4522e871399cd717abdd847ab11fe88');
          }
        }
      }
      return Array.from(addresses.values());
    },
    filteredCategories() {
      return _.filter(this.categories, (m) => m.type === 'Asset');
    },
    filteredContacts() {
      return this.contacts;
      // if (this.txnType === "receive") {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Customer"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // } else {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Vendor"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // }
    },
  },
};
</script>
