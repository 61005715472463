<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-layout row wrap v-if="isLoading">
        <v-flex xs12 text-xs-center>
          <v-progress-circular indeterminate color="accent" :size="25" :width="3"></v-progress-circular>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-else>
        <v-flex xs12>
          <v-layout row wrap>
            <v-flex xs2> From </v-flex>
            <v-flex xs4>{{ fromAddress }}</v-flex>
            <v-flex xs2> To: </v-flex>
            <v-flex xs4>{{ toAddress }}</v-flex>
            <v-flex xs12 v-if="!totalHoldings">
              <v-btn flat color="blue" @click="calculateTotalHoldings">Load Total Staked Details</v-btn>
            </v-flex>
            <v-flex xs12 v-else>
              <v-layout row wrap>
                <v-flex xs2> New Holdings </v-flex>
                <v-flex xs4>
                  <ul class="list-straight">
                    <li
                      v-for="(asset, ei) in totalHoldings.fromAssets"
                      v-bind:key="`fa-${ei}`"
                      style="list-style: none"
                    >
                      {{ asset.addressOwned }} {{ asset.symbol }}
                    </li>
                  </ul>
                </v-flex>
                <v-flex xs4 offset-xs2>
                  <ul class="list-straight">
                    <li v-for="(asset, ei) in totalHoldings.toAssets" v-bind:key="`fa-${ei}`" style="list-style: none">
                      {{ asset.addressOwned }} {{ asset.symbol }}
                    </li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap v-if="cashFlowLines">
        <v-flex xs12>
          <h4>Revenue and Expense Recognition</h4>
        </v-flex>
        <v-flex xs12 v-for="(item, index) in cashFlowLines" v-bind:key="`cf-${index}`">
          <v-layout row wrap>
            <v-flex xs3 pa-1>
              <v-autocomplete
                :items="contacts"
                :label="$tc('_contact', 1)"
                v-model="item.contact"
                item-text="name"
                item-value="id"
                return-object
                :disabled="readonly"
                v-on:change="updateTransactionData"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs3 pa-1>
              <v-autocomplete
                :items="categories"
                :label="$tc('_category', 1)"
                v-model="item.category"
                item-text="name"
                item-value="id"
                return-object
                :disabled="readonly"
                v-on:change="updateTransactionData"
              >
              </v-autocomplete>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field label="Symbol" :disabled="readonly" v-model="item.symbol"></v-text-field>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field
                label="Amount in Crypto"
                :disabled="readonly"
                v-model="item.amount"
                v-on:change="updateTransactionData"
              ></v-text-field>
            </v-flex>
            <v-flex xs2 pa-1>
              <v-text-field
                label="Fiat Value"
                :disabled="readonly"
                :value="calculateValueInFiat(item.symbol, item.amount)"
                readonly
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <h4>Fee</h4>
    </v-flex>
    <v-flex xs9>
      <v-autocomplete
        v-model="feeContactId"
        :disabled="readonly"
        :items="contacts"
        :label="'Fee Contact (Exchange or Network)'"
        item-text="name"
        item-value="id"
        persistent-hint
        v-on:change="updateTransactionData"
      >
      </v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <hr />
    </v-flex>
    <v-flex xs12>
      <cost-basis
        :dynamic-symbols="poolSymbols"
        :readonly="readonly"
        ref="cbComponent"
        :amounts="txn.amounts"
        :txn-type="txnType"
        v-model="costBasis"
        v-on:input="updateTransactionData"
        :include-fees="true"
        :txn="txn"
        :show-invoice="false"
      ></cost-basis>
    </v-flex>
  </v-layout>
</template>
<script>
import * as axios from 'axios';
import * as _ from 'underscore';

import { isStrEmpty } from '@/utils/stringUtils';

import { baConfig } from '../../../../config';
import CostBasis from './CostBasis';

export default {
  components: {
    CostBasis,
  },
  props: ['value', 'txn', 'categories', 'contacts', 'txnType', 'readonly'],
  data() {
    return {
      costBasis: null,
      description: '',
      category: '',
      contact: '',
      stakedAddress: '0x0',
      isLoading: true,
      stakingType: null,
      tokenDetailsBySymbol: {},
      tokenDetailsByAddress: {},
      stakingPlatform: '',
      stakingTxns: [],
      pools: [],
      poolDetails: {},
      userAddress: '0x0',
      totalHoldings: null,
      feeContactId: null,
      stakingTarget: null,
      tokenActionLabel: '',
      stakingHistory: null,
      cashFlowLines: null,
      showOther: false,
      poolSymbols: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    this.populateForm();
    await this.getAddressDetails();

    this.updateTransactionData();

    this.isLoading = false;
  },
  methods: {
    tryAddPoolSymbol(symbol) {
      const f = this.poolSymbols.find((m) => m === symbol);
      if (f === undefined) {
        this.poolSymbols.push(symbol);
      }
    },
    async getPoolHoldings(address, block) {
      const fromHoldingsUrl = `${baConfig.addressSvcUrl}/networks/eth/addresses/${address}/balance?asset=${this.poolDetails.address}&block=${block}`;
      const ph = await axios.get(fromHoldingsUrl);
      return ph;
    },
    async calculateTotalHoldings() {
      console.log(this.poolDetails);
      // Get the user's total pool holdings
      const block = this.txn.blocks[0];
      const fromPoolHoldings = await this.getPoolHoldings(this.fromAddress, block);
      const fph = this.bn(fromPoolHoldings.data.result);

      const toPoolHoldings = await this.getPoolHoldings(this.toAddress, block);
      const tph = this.bn(toPoolHoldings.data.result);

      const poolSupplyUrl = `${baConfig.addressSvcUrl}/networks/eth/addresses/${this.poolDetails.address}/supply?block=${block}`;
      const poolSupply = await axios.get(poolSupplyUrl);

      const ps = this.bn(poolSupply.data.result);
      const fromPoolPercent = fph.div(ps);
      const toPoolPercent = tph.div(ps);

      const fromAssets = [];
      const toAssets = [];
      for (const asset of this.poolDetails.assets) {
        const assetDecimals = asset.asset.decimals;
        let poolAssetOwnershipUrl;
        if (asset.asset.type === 'token-pointer') {
          poolAssetOwnershipUrl = `${baConfig.addressSvcUrl}/networks/eth/addresses/${this.poolDetails.address}/balance?asset=${asset.asset.address}&block=${block}`;
        } else if (asset.asset.type === 'blockchain-currency-pointer') {
          poolAssetOwnershipUrl = `${baConfig.addressSvcUrl}/networks/eth/addresses/${this.poolDetails.address}/balance?asset=${asset.asset.symbol}&block=${block}`;
        } else {
          throw new Error('Unable to handle address type');
        }

        const poolAssetOwnership = await axios.get(poolAssetOwnershipUrl);
        const pao = this.bn(poolAssetOwnership.data.result);
        console.log('PAO', poolAssetOwnership, pao);
        this.tryAddPoolSymbol(asset.symbol);

        const fa = {
          symbol: asset.symbol,
          decimals: asset.decimals,
          poolOwned: pao.div(10 ** assetDecimals).toNumber(),
          addressOwned: pao
            .mul(fromPoolPercent)
            .div(10 ** assetDecimals)
            .toDecimalPlaces(3)
            .toNumber(),
        };
        fromAssets.push(fa);

        const ta = {
          symbol: asset.symbol,
          decimals: asset.decimals,
          poolOwned: pao.div(10 ** assetDecimals).toNumber(),
          addressOwned: pao
            .mul(toPoolPercent)
            .div(10 ** assetDecimals)
            .toDecimalPlaces(3)
            .toNumber(),
        };
        toAssets.push(ta);
      }

      console.log('tots', fromAssets, toAssets, poolSupply);

      this.totalHoldings = {
        fromAssets,
        toAssets,
        poolSupply: poolSupply.data.result,
      };

      this.updateTransactionData();
    },
    calculateValueInFiat(coin, value) {
      if (!coin || !value) {
        return 0;
      }

      if (this.costBasis) {
        const er = this.$refs.cbComponent.exchangeRateForCurrency(coin);
        const val = er * value;
        const bn = this.bn(val);
        return bn.toDecimalPlaces(2).toNumber();
      } else {
        return 0;
      }
    },
    populateForm() {
      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        if (ad.assetStaking) {
          // Do stuff
          const ast = ad.assetStaking;

          if (ast.fee && ast.fee.feeContactId) {
            this.feeContactId = ast.fee.feeContactId;
          }

          if (ast.poolDetails) {
            const assets = [];
            if (ast.totalHoldings) {
              for (const th of ast.totalHoldings) {
                assets.push({
                  addressOwned: th.value,
                  symbol: th.symbol,
                });
              }
            }

            this.totalHoldings = {
              userPoolHoldings: ast.poolDetails.addressPoolTokens,
              poolSupply: ast.poolDetails.totalPoolTokens,
              percentOfPool: this.bn(ast.poolDetails.percentOfPool),
              assets,
            };
          }

          if (ast.stakingTarget) {
            // do stuff
          }

          // const foundCategory = this.categories.find(
          //   m => m.id === ad.staking.categoryId
          // );
          // this.category = foundCategory;
          //
          // const foundContact = this.contacts.find(
          //   m => m.id === ad.staking.contactId
          // );
          // this.contact = foundContact;
        } else {
          // throw new Error("Bad accounting details");
        }
      }
    },
    updateTransactionData() {
      const transactionData = {};
      transactionData.valid = true;
      const errors = [];

      const baseCurrency = this.$store.state.currentOrg.baseCurrency;

      let fromTotalHoldings, toTotalHoldings;
      if (this.totalHoldings) {
        fromTotalHoldings = this.totalHoldings.fromAssets.map((m) => {
          return {
            value: m.addressOwned,
            symbol: m.symbol,
          };
        });

        toTotalHoldings = this.totalHoldings.toAssets.map((m) => {
          return {
            value: m.addressOwned,
            symbol: m.symbol,
          };
        });

        // poolDetails = {
        //   addressPoolTokens: this.totalHoldings.userPoolHoldings,
        //   totalPoolTokens: this.totalHoldings.poolSupply,
        // };
      } else {
        transactionData.valid = false;
        errors.push('Total Holdings must be loaded and valid');
      }

      const exchangeRates = [];
      for (const er of this.$refs.cbComponent.exchangeRates) {
        exchangeRates.push({
          coin: er.coin,
          unit: er.unit,
          fiat: er.fiat,
          rate: er.rate,
          source: er.source,
        });
      }
      //
      // type DeFiTransferTransactionDetail {
      //   stakingTarget: AddressPointer!
      //       fromAddress: AddressPointer!
      //       toAddress: AddressPointer!
      //
      //       fromTotalHoldings: [ValueDetail]
      //   toTotalHoldings: [ValueDetail]
      //
      //   withdrawnAssets: [ValueDetail]
      //   recognizedRevenue: [MultiValueTransactionItem]
      //
      //   poolDetails: AssetStakingPoolDetails
      //
      //   block: BlockPointer!
      //
      //       exchangeRates: [ExchangeRateDetails]!
      //       fee: DetailedMultiFee
      // }

      let stakingTarget;
      if (this.stakingTarget) {
        stakingTarget = {
          networkId: this.stakingTarget.item.networkId,
          address: this.stakingTarget.item.address,
        };
      } else {
        console.log('No staking target');
        transactionData.valid = false;
      }

      transactionData.deFiTransfer = {
        stakingTarget,
        fromTotalHoldings,
        toTotalHoldings,
        fromAddress: this.from,
        toAddress: this.to,
        // poolDetails,
        exchangeRates,
        // block,
        // holdingAddress,
        // stakingTarget
      };

      if (this.costBasis.fees && this.costBasis.fees.length > 0) {
        const feeItems = this.costBasis.fees.map((m) => {
          return {
            amount: m.amount,
            costBasis: m.costBasis,
          };
        });

        transactionData.deFiTransfer.fee = {
          feeItems,
          feeContactId: this.feeContactId,
        };

        if (feeItems.length > 0 && this.feeContactId === null) {
          transactionData.valid = false;
        }
      }

      if (this.txn && this.txn.blocks.length > 0) {
        transactionData.deFiTransfer.block = {
          networkId: this.txn.networkId,
          blockId: this.txn.blocks[0],
        };
      } else {
        transactionData.valid = false;
      }

      if (this.cashFlowLines) {
        const recognizedRevenue = [];
        for (const l of this.cashFlowLines) {
          if (isStrEmpty(l.symbol) || isStrEmpty(l.amount)) {
            transactionData.valid = false;
            break;
          }

          if (!l.contact) {
            transactionData.valid = false;
            break;
          }

          if (!l.category) {
            transactionData.valid = false;
            break;
          }

          const tl = {
            categoryId: l.category.id,
            sourceTicker: l.symbol,
            sourceAmount: l.amount.toString(),
            fiat: baseCurrency,
            fiatAmount: this.calculateValueInFiat(l.ticker, l.amount).toString(),
          };

          const split = {
            contactId: l.contact.id,
            transactionType: this.txnAmountTotal > 0 ? 'Income' : 'Expense',
            lines: [tl],
          };
          recognizedRevenue.push(split);
        }
        transactionData.assetStaking.recognizedRevenue = recognizedRevenue;
      }

      console.log(transactionData);
      this.$emit('input', transactionData);
    },
    async getAddressDetails() {
      if (this.transferredAsset) {
        const tokenAddress = this.transferredAsset?.address;

        const resp = await axios.get(`${baConfig.addressSvcUrl}/networks/eth/addresses/${tokenAddress}`);

        let addressData;
        if (resp && resp.status === 200) {
          addressData = resp.data;
        } else {
          throw new Error('Problem getting address details');
        }

        if (addressData.item.type === 'token-liquidity-pool' || addressData.item.type === 'token-based-vault') {
          this.poolDetails = addressData.item;
          this.stakingPlatform = addressData.item.platformId;
          this.stakingTarget = addressData;
        }
      } else {
        console.log('No transferred asset');
      }
    },
  },
  computed: {
    from() {
      if (this.txn && this.txn.txnLogs.length > 0) {
        const t = this.txn.txnLogs[0].from;
        return {
          address: t.address,
          networkId: t.networkId,
        };
      }

      return {
        address: '',
        networkId: '',
      };
    },
    fromAddress() {
      if (this.from) {
        return this.from.address.toLowerCase();
      }
      return '';
    },
    to() {
      if (this.txn && this.txn.txnLogs.length > 0) {
        const t = this.txn.txnLogs[0].to;
        return {
          address: t.address,
          networkId: t.networkId,
        };
      }

      return {
        address: '',
        networkId: '',
      };
    },
    toAddress() {
      if (this.to) {
        return this.to.address.toLowerCase();
      }

      return '';
    },
    transferredAsset() {
      if (this.txn && this.txn.txnLogs.length > 0) {
        return this.txn.txnLogs[0].asset;
      }

      return undefined;
    },
    selectedStakingTxns() {
      return this.stakingTxns.filter((m) => m.checked === true);
    },
    sentCoins() {
      return this.txn.amounts.filter((m) => this.bn(m.value).lt(0));
    },
    showStakingTxns() {
      return this.stakingTxns.filter((m) => m.showDefault === true);
    },
    otherStakingTxns() {
      return this.stakingTxns.filter((m) => m.showDefault === false);
    },
    interactionAddresses() {
      const addresses = new Set();
      for (const txn of this.txn.txnLogs) {
        if (txn.from.address && txn.from.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.from.address);
        }

        if (txn.to.address && txn.to.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.to.address);
        }

        if (txn.asset.address && txn.asset.address !== '0x0000000000000000000000000000000000000000') {
          addresses.add(txn.asset.address);
        }
      }
      return Array.from(addresses.values());
    },
    filteredCategories() {
      return _.filter(this.categories, (m) => m.type === 'Asset');
    },
    filteredContacts() {
      return this.contacts;
      // if (this.txnType === "receive") {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Customer"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // } else {
      //   const filtered = _.filter(
      //     this.contacts,
      //     m => !m.type || m.type === "None" || m.type === "Vendor"
      //   );
      //   const sortedByName = _.sortBy(filtered, m => m.name);
      //   return sortedByName;
      // }
    },
  },
};
</script>
