import * as math from 'mathjs';

/**
 *
 * @param sourceAmount - the paid crypto amount, should be in the same currency as the current exchange rate
 * @param currentExchangeRate - the current exchange rate
 * @param amountPaid - the paid amount in non functional currency
 * @param historicExchangeRate - the rate the invoice was posted in the ERP
 * @returns
 */
export const calculateForex = (
  sourceAmount: string,
  currentExchangeRate: string | 0,
  amountPaid: string,
  historicExchangeRate: number | undefined | null
) => {
  //    assumption: the base currency in Bitwave is the base currency in ERP
  //    if that does not hold true then nothing works

  //    we have the exchange rate using functional, so we know how much it should
  //    debit or credit digital assets

  //    using the exchange rate on the invoice object, we know how much it debited or credited
  //    when the transaction was booked

  //    and that's how we calc forex

  //    we want to get the fmv of the source amount
  const parsedSourceAmount = math.bignumber(sourceAmount);
  const fiatValueOfSourceAmount = parsedSourceAmount.mul(currentExchangeRate);

  //    we want to get the paid amount in base currency of when it was created
  const invoiceExchangeRate = historicExchangeRate ?? 1;
  const fiatValueOfInvoicePaidHistoricRate = math.bignumber(amountPaid).mul(invoiceExchangeRate);

  return fiatValueOfSourceAmount.sub(fiatValueOfInvoicePaidHistoricRate);
};
