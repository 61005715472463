import gql from 'graphql-tag';
export const NoFiatTransactionsPageQuery = gql`
  query GetTransaction($orgId: ID!, $transactionId: ID!) {
    transaction(orgId: $orgId, transactionId: $transactionId) {
      id
      created
      description
      editEtag
      ignore
      networkId
      errored
      errors
      memo
      type
      reconciliationStatus
      categorizationStatus
      isCombined
      isCombinedSubTransaction
      readonly
      to {
        address
      }
      from {
        address
      }
      txnLogs {
        type
        from {
          networkId
          address
        }
        to {
          networkId
          address
        }
        amount
        asset {
          type
          networkId
          address
          symbol
        }
      }
      blocks
      accountingDetails {
        sell {
          dateTimeSec
          source
          sourceAmount
          target
          targetAmount
          exchangeRate
          proceedsAccount
          fee
          feeContact
        }
        simple {
          contactId
          categoryId
          description
          costBasis {
            cost
            exchangeRate
            costBasisType
            notes
            invoiceId
          }
        }
        detailed {
          costBasis {
            exchangeRate
          }
          items {
            contactId
            lines {
              amount {
                value
                coin
                unit
              }
              description
              categoryId
            }
          }
        }
        multivalue {
          notes
          items {
            contactId
            transactionType
            lines {
              categoryId
              description
              coin
              unit
              coinAmount
              fiat
              fiatAmount
              walletId
              sourceValue {
                type
                amount
                ticker
              }
              metadata {
                id
              }
              forceZeroGainLoss
            }
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
        }
        invoice {
          invoices {
            invoiceId
            ticker
            amount
            contactId
            walletId
            forex {
              fiat
              categoryId
              amount
            }
            coin
            unit
            coinAmount
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          fees {
            fee {
              displayValue
              coin
              unit
            }
            cost {
              cost
              exchangeRate
              currency
            }
            feePayeeId
            walletId
          }
        }
        staking {
          contactId
          categoryId
          description
          costBasis {
            cost
            exchangeRate
            costBasisType
            notes
            invoiceId
          }
          stakingTxnType
        }
        assetStaking {
          type
          stakedAssets {
            symbol
            value
          }
          totalHoldings {
            symbol
            value
          }
          poolDetails {
            addressPoolTokens
            totalPoolTokens
            percentOfPool
          }
          block {
            networkId
            blockId
          }
          holdingAddress {
            networkId
            address
          }
          stakingTarget {
            networkId
            address
          }
          withdrawnAssets {
            value
            symbol
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          recognizedRevenue {
            contactId
            transactionType
            lines {
              categoryId
              description
              coin
              unit
              coinAmount
              fiat
              fiatAmount
              sourceValue {
                type
                amount
                ticker
              }
            }
          }
          fee {
            feeItems {
              amount {
                value
                symbol
              }
              costBasis {
                cost
                exchangeRate
                costBasisType
                notes
                invoiceId
              }
            }
            feeContactId
          }
        }
        deFiTransfer {
          fromTotalHoldings {
            symbol
            value
          }
          toTotalHoldings {
            symbol
            value
          }
          poolDetails {
            addressPoolTokens
            totalPoolTokens
            percentOfPool
          }
          block {
            networkId
            blockId
          }
          stakingTarget {
            networkId
            address
          }
          fromAddress {
            networkId
            address
          }
          toAddress {
            networkId
            address
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          fee {
            feeItems {
              amount {
                value
                symbol
              }
              costBasis {
                cost
                exchangeRate
                costBasisType
                notes
                invoiceId
              }
            }
            feeContactId
          }
        }
        billPay {
          billId
          contactId
          categoryId
          description
          costBasis {
            cost
            exchangeRate
            costBasisType
            notes
            invoiceId
          }
        }
        trade {
          acquiredAssets {
            asset {
              displayValue
              coin
              unit
              value
            }
            fairMarketValue {
              cost
              exchangeRate
              currency
            }
            walletId
          }
          disposedAssets {
            asset {
              displayValue
              coin
              unit
              value
            }
            fairMarketValue {
              cost
              exchangeRate
              currency
            }
            walletId
          }
          tradeFee {
            assets {
              asset {
                displayValue
                coin
                unit
              }
              fairMarketValue {
                cost
                exchangeRate
                currency
              }
              walletId
            }
            feeContactId
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          systemCreated
          carryForwardCostBasis
        }
        simpleTrade {
          acquired {
            displayValue
            coin
            value
          }
          disposed {
            displayValue
            coin
            value
          }
          acquiredCost {
            cost
            exchangeRate
            currency
          }
          disposedCost {
            cost
            exchangeRate
            currency
          }
          fee {
            fee {
              displayValue
              value
              coin
              unit
            }
            cost {
              cost
              exchangeRate
              currency
            }
            feePayeeId
          }
          systemCreated
        }
        transfer {
          notes
          feeContactId
          feeCostBasis {
            cost
            exchangeRate
            costBasisType
            notes
            invoiceId
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
        }
        accountTransfer {
          description
          otherAccountId
          feeContactId
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          metadataIds
        }
        advanceDeFi {
          items {
            defiWalletId
            lines {
              ... on AdvanceDeFiLineNonPost {
                type
                sourceTicker
                sourceAmount
                isNonVaultTracking
                walletId
              }
              ... on AdvanceDeFiLinePost {
                type
                contactId
                categoryId
                description
                sourceTicker
                sourceAmount
                fiat
                fiatAmount
                metadata {
                  id
                }
                isNonVaultTracking
              }
            }
          }
          exchangeRates {
            coin
            coinUnit
            fiat
            rate
          }
          fees {
            fee {
              displayValue
              coin
              unit
            }
            cost {
              cost
              exchangeRate
              currency
            }
            feePayeeId
          }
        }
      }
      exchangeRates {
        coin
        coinUnit
        fiat
        rate
        priceId
      }
      amounts {
        value
        unit
        displayValue
        coin
      }
      amountsIncludingFees {
        value
        unit
        displayValue
        coin
      }
      fullAmountSet {
        value
        unit
        displayValue
        coin
      }
      fullAmountSetWithoutFees {
        value
        unit
        displayValue
        coin
      }
      paidFees {
        value
        unit
        displayValue
        coin
      }
      wallet {
        id
        name
        addresses
        address
      }
      details {
        to
        from
        amounts {
          coin
          unit
          value
        }
      }
      matchedInvoices {
        id
        type
        title
        date
        dueDate
        totalAmount
        currency
      }
      viewLinks {
        link
        name
      }
      txnLines {
        id
        subId
        dateTimeSEC
        operation
        asset
        amount
        exchangeRate
        valueInBaseCurrency
        walletId
        walletName
        from
        to
        feeAmount
        feeAsset
        t
      }
      accountingConnectionId
    }
  }
`;
