<template>
  <v-layout row wrap>
    <v-flex xs6>
      <table class="v-table text-xs-left">
        <tr>
          <td><h4>Exchange Rates</h4></td>
          <td>Suggested Rate</td>
          <td colspan="1">Using Rate</td>
          <td></td>
        </tr>
        <tr v-for="(exchangeRate, index) in flatExchangeRates" v-bind:key="`er-${index}`">
          <td>{{ exchangeRate.coin }}:{{ exchangeRate.fiat }}</td>
          <td>
            <!--            {{ getSymbolForCurrency(exchangeRate.fiat) }}-->
            <!--            {{ formatCurrency(exchangeRate.systemRate) }}-->
            <price
              :price-id="exchangeRate.priceId"
              :price-fiat="exchangeRate.fiat"
              :price="exchangeRate.systemRate"
              :enabled="checkFeatureFlag('price-details', features)"
            ></price>
          </td>
          <td v-if="exchangeRate.editing === false">
            <span>
              {{ getSymbolForCurrency(exchangeRate.fiat) }}
              {{ formatCurrency(exchangeRate.rate) }}
            </span>
          </td>
          <td v-if="exchangeRate.editing === true">
            <v-text-field
              :rules="[rules.number]"
              v-model="exchangeRate.rate"
              v-on:change="updateCostBasis"
            ></v-text-field>
          </td>
          <td v-if="!readonly && exchangeRate.canEdit && !exchangeRate.editing && allowEditing">
            <v-btn @click="editRate(exchangeRate)" flat x-small icon>
              <v-icon>edit</v-icon>
            </v-btn>
          </td>
          <td v-if="!readonly && exchangeRate.canEdit && exchangeRate.editing && allowEditing">
            <v-btn @click="saveEdit(exchangeRate)" flat x-small icon>
              <v-icon>check</v-icon>
            </v-btn>
            <v-btn @click="cancelEdit(exchangeRate)" flat x-small icon>
              <v-icon>cancel</v-icon>
            </v-btn>
            <br />
          </td>
        </tr>
      </table>
    </v-flex>
    <v-flex xs6 v-if="showCostBasis !== false">
      <table class="v-table text-xs-left" v-if="showCostBasis !== false">
        <tr>
          <td colspan="3"><h4>Transaction Values</h4></td>
        </tr>
        <tr v-for="(amount, index) in nonFeeAmounts" v-bind:key="`c-${index}`">
          <td></td>
          <td>
            <b>{{ coinSymbol(amount.coin) }}</b>
            {{ formatAccounting(amount.value, null, true) }}
          </td>
          <td>
            {{ sym() }}
            {{ formatAccounting(calcTxnCostBasisFromAmount(amount), null, true) }}
          </td>
        </tr>
        <template v-if="hasFee">
          <tr v-for="(item, index) in txn.paidFees" v-bind:key="`f-${index}`">
            <td>
              <b>Fee</b>
            </td>
            <td>
              <b>{{ coinSymbol(item.coin) }}</b>
              {{ formatAccounting(item.displayValue, true, true) }}
            </td>
            <td>{{ sym() }} ( {{ formatAccounting(calcTxnCostBasisFromAmount(item), null, true) }} )</td>
          </tr>
        </template>
        <tr>
          <td colspan="3">
            <v-divider></v-divider>
          </td>
        </tr>
        <tr>
          <td></td>
          <td><b>Total</b></td>
          <td>{{ sym() }} {{ formatAccounting(calcTotalCostBasisReal, null, true) }}</td>
        </tr>
      </table>
    </v-flex>
    <v-flex xs6 v-if="isLoading">
      <v-progress-linear slot="progress" color="accent" height="2" indeterminate></v-progress-linear>
    </v-flex>
    <v-flex xs12 my-3>
      <v-divider></v-divider>
    </v-flex>
  </v-layout>
</template>
<script>
import gql from 'graphql-tag';
import _ from 'lodash';
import * as math from 'mathjs';

import Price from '@/components/pricing/Price';

import { convertUnits, getMainUnitForCoin, getSymbolForCoin, getSymbolForCurrency } from '../../../utils/coinUtils';

export default {
  components: { Price },
  props: [
    'value',
    'txn',
    'invoices',
    'txnType',
    'showInvoice',
    'feeExchangeRates', // this is only used for transfers currently
    'showCostBasis',
    'readonly',
    'mode',
    'includeFees',
    'netToFees',
    'dynamicSymbols',
  ],
  data() {
    return {
      costBasisType: 'exchangeRate',
      manuallySetExchangeRate: null,
      manuallySetCostBasis: null,
      manualCostBasisNotes: '',
      connectedInvoice: null,
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
      },
      exchangeRates: null,
      savedCostBasis: null,
      isLoading: false,
    };
  },
  mounted() {
    this.populateForm();
    this.updateCostBasis();
  },
  methods: {
    getSymbolForCurrency(coin) {
      return getSymbolForCurrency(coin);
    },
    coinSymbol(coin) {
      return getSymbolForCoin(coin);
    },
    editRate(exchangeRate) {
      exchangeRate.editing = true;
      exchangeRate.oldRate = exchangeRate.rate;
    },
    cancelEdit(exchangeRate) {
      exchangeRate.editing = false;
      exchangeRate.rate = exchangeRate.oldRate;
    },
    saveEdit(exchangeRate) {
      exchangeRate.editing = false;
      exchangeRate.source = 'Manual';
      // exchangeRate.old = exchangeRate.oldRate;
    },
    calcTxnCostBasisFromAmount(amount) {
      // get selected exchange rate
      try {
        const val = math.bignumber(amount.value);
        const rate = this.exchangeRateForCurrency(amount.coin);
        const v = val.mul(rate);
        const ret = Number(v.toFixed(2));
        return ret;
      } catch (e) {
        return 0;
      }
    },
    exchangeRateForCurrency(currency) {
      // get selected exchange rate
      if (this.exchangeRates) {
        const ro = this.exchangeRates.find((m) => m.coin === currency); // .get(currency);
        if (ro === null || ro === undefined || isNaN(ro.rate)) {
          return 0;
        } else {
          return ro.rate;
        }
      } else {
        return 0;
      }
    },
    populateForm() {
      let overrideExchangeRates;

      if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
        const ad = this.txn.accountingDetails[0];
        let cb;
        if (ad.simple) {
          cb = ad.simple.costBasis;
        } else if (ad.transfer && ad.transfer.exchangeRates) {
          overrideExchangeRates = ad.transfer.exchangeRates;
        } else if (ad.transfer) {
          cb = ad.transfer.feeCostBasis;
        } else if (ad.detailed) {
          cb = ad.detailed.costBasis;
        } else if (ad.invoice) {
          overrideExchangeRates = ad.invoice.exchangeRates;
        } else if (ad.multivalue) {
          overrideExchangeRates = ad.multivalue.exchangeRates;
        } else if (ad.trade && ad.trade.exchangeRates) {
          overrideExchangeRates = ad.trade.exchangeRates;
        } else if (ad.assetStaking && ad.assetStaking.exchangeRates) {
          overrideExchangeRates = ad.assetStaking.exchangeRates;
        } else if (ad.deFiTransfer && ad.deFiTransfer.exchangeRates) {
          overrideExchangeRates = ad.deFiTransfer.exchangeRates;
        }

        if (cb) {
          this.savedCostBasis = cb;
          switch (cb.costBasisType) {
            case 'NoConversion':
              this.costBasisType = 'fixed';
              break;
            case 'ExchangeRate':
              this.costBasisType = 'exchangeRate';
              break;
            case 'Manual': {
              this.costBasisType = 'manual';
              this.manualCostBasis = cb.cost;
              this.manualExchangeRate = cb.exchangeRate;
              this.manualCostBasisNotes = cb.notes;
              break;
            }
            case 'Invoice': {
              this.costBasisType = 'invoice';
              // this.invoiceCostBasis = cb.cost;
              // this.invoiceExchangeRate = cb.exchangeRate;
              const foundInvoice = this.invoices.find((m) => m.id === cb.invoiceId);
              if (foundInvoice) {
                this.connectedInvoice = foundInvoice.id;
              } else {
                console.log('Error finding matching invoice');
              }

              break;
            }
            default:
              break;
          }
        }
      } else {
        if (this.changeableExchangeRate) {
          this.costBasisType = 'exchangeRate';
        } else {
          this.costBasisType = 'fixed';
        }
      }

      this.setExchangeRates(overrideExchangeRates);
    },
    setExchangeRates(override) {
      // const rates = new Map();
      const exchangeRates = this.txn.exchangeRates || [];
      const rates = [];
      for (const er of exchangeRates) {
        let rate = er.rate;
        if (override) {
          const overrideRate = override.find((m) => m.coin === er.coin);
          if (overrideRate) {
            rate = overrideRate.rate;
          }
        }

        const ro = {
          rate: rate,
          systemRate: er.rate,
          overrideRate: null,
          coin: er.coin,
          unit: er.coinUnit,
          fiat: er.fiat,
          canEdit: true,
          editing: false,
          source: er.source,
          priceId: er.priceId,
        };

        if (this.costBasisType === 'exchangeRate' && this.savedCostBasis && this.txn.exchangeRates?.length === 1) {
          ro.rate = this.savedCostBasis.exchangeRate;
        }

        rates.push(ro);
        // rates.set(er.coin, ro);
      }

      this.exchangeRates = rates;
      this.updateCostBasis();
    },
    async ensureDynamicExchangeRateExists(symbol) {
      const exists = this.exchangeRates.find((m) => m.coin === symbol);
      if (exists === undefined) {
        console.log('going remote for ' + symbol);
        this.isLoading = true;
        try {
          const erResp = await this.getExchangeRate(symbol, this.txn.created);
          const er = erResp.data.exchangeRates.lookup;
          let ro;
          if (er.found) {
            ro = {
              rate: er.rate.rate,
              systemRate: er.rate.rate,
              overrideRate: null,
              coin: er.coin,
              unit: er.coinUnit,
              fiat: er.fiat,
              canEdit: true,
              editing: false,
              source: 'Automatic',
              priceId: er.priceId,
            };
          } else {
            ro = {
              rate: 0,
              systemRate: 0,
              overrideRate: null,
              coin: er.coin,
              unit: er.coinUnit,
              fiat: er.fiat,
              canEdit: true,
              editing: false,
              source: 'Automatic',
              priceId: er.priceId,
            };
          }

          const existsCheck = this.exchangeRates.find((m) => m.coin === symbol);
          if (!existsCheck) {
            this.exchangeRates.push(ro);
          }

          this.updateCostBasis();
        } finally {
          this.isLoading = false;
        }
      }
    },
    async getExchangeRate(symbol, dateTimeSEC) {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        fromSymbol: symbol,
        dateTimeSEC,
      };

      const res = await this.$apollo.query({
        query: gql`
          query exchangeRateLookup($orgId: ID!, $fromSymbol: String!, $dateTimeSEC: Int!) {
            exchangeRates {
              lookup(orgId: $orgId, fromSymbol: $fromSymbol, dateTimeSEC: $dateTimeSEC) {
                found
                toSymbol
                coin
                coinUnit
                fiat
                rate {
                  rate
                  displayRate
                  dateTime
                }
                priceId
              }
            }
          }
        `,
        // Parameters
        variables: vars,
        fetchPolicy: 'network-only',
      });

      return res;
    },
    updateCostBasis() {
      let cb;
      const exchangeRates = this.feeExchangeRates
        ? this.exchangeRates.filter((er) => this.feeExchangeRates[er.coin] === er.unit)
        : this.exchangeRates;
      if (exchangeRates) {
        const er = exchangeRates.map((m) => {
          return {
            coin: m.coin,
            unit: m.unit,
            fiat: m.fiat,
            rate: m.rate,
            source: m.source,
            priceId: m.priceId,
          };
        });
        if (this.costBasisType === 'exchangeRate') {
          cb = {
            cost: this.calcTotalCostBasis,
            exchangeRate: math.bignumber(er[0]?.rate ?? 0).toNumber(),
            currency: this.fiat,
            costBasisType: 'ExchangeRate',
            valid: true,
            exchangeRates: er,
          };
        } else if (this.costBasisType === 'fixed') {
          cb = {
            cost: this.calcTotalCostBasis,
            exchangeRate: 1,
            currency: this.fiat,
            costBasisType: 'NoConversion',
            valid: true,
            exchangeRates: er,
          };
        } else if (this.costBasisType === 'manual') {
          let valid = false;
          if ((this.manuallySetExchangeRate !== null) | (this.manuallySetCostBasis !== null)) {
            valid = true;
          }
          cb = {
            cost: Number(this.manualCostBasis),
            exchangeRate: Number(this.manualExchangeRate),
            notes: this.manualCostBasisNotes,
            currency: this.fiat,
            costBasisType: 'Manual',
            valid,
          };
        }

        if (this.includeFees === true) {
          cb.fees = this.feeDetails;
        }

        // return cb;
        this.$emit('input', cb);
      }
    },
  },
  computed: {
    manualExchangeRate: {
      get() {
        if (this.manuallySetExchangeRate === null && this.manuallySetCostBasis === null) {
          return '';
        } else if (this.manuallySetExchangeRate !== null && this.manuallySetCostBasis === null) {
          return this.manuallySetExchangeRate;
        } else if (this.manuallySetCostBasis !== null && this.manuallySetExchangeRate === null) {
          const cb = math.bignumber(this.manuallySetCostBasis);
          const v = math.bignumber(this.txnValue);
          return cb.div(v).toFixed(2);
        } else {
          return 'err';
        }
      },
      set(newVal) {
        if (newVal === '') {
          this.manuallySetCostBasis = 0;
        } else {
          this.manuallySetExchangeRate = newVal;
        }

        this.manuallySetCostBasis = null;
        this.updateCostBasis();
      },
    },
    manualCostBasis: {
      get() {
        if (this.manuallySetExchangeRate === null && this.manuallySetCostBasis === null) {
          return '';
        } else if (this.manuallySetCostBasis !== null && this.manuallySetExchangeRate === null) {
          return this.manuallySetCostBasis;
        } else if (this.manuallySetExchangeRate !== null && this.manuallySetCostBasis === null) {
          const ex = math.bignumber(this.manuallySetExchangeRate);
          const v = math.bignumber(this.txnValue);
          return ex.mul(v).toFixed(2);
        } else {
          return 'err';
        }
      },
      set(newVal) {
        this.manuallySetExchangeRate = null;
        if (newVal === '') {
          this.manuallySetCostBasis = 0;
        } else {
          this.manuallySetCostBasis = newVal;
        }

        this.updateCostBasis();
      },
    },
    allowEditing() {
      if (this.mode === 'calculated') {
        return false;
      }

      return true;
    },
    nonFeeAmounts() {
      return this.txn.amounts;
    },
    amounts() {
      if (this.txn.fullAmountSet) {
        return this.txn.fullAmountSet;
        // return _.filter(this.txn.fullAmountSet, m => m.value !== "0");
      } else {
        return _.filter(this.txn.amounts, (m) => m.value !== '0');
      }
    },
    hasFee() {
      if (this.netToFees === true) {
        return false;
      } else {
        return this.txn.paidFees !== null && this.txn.paidFees.length > 0;
      }

      // return (
      //   this.txnType === "spend" &&
      //   this.txn.fees !== null &&
      //   this.txn.fees.length > 0 &&
      //   this.txn.fees[0] !== null &&
      //   this.txn.fees[0].value > 0
      // );
    },
    // exchangeRates() {
    //   const rates = new Map();
    //   for(const er of this.txn.exchangeRates) {
    //     const ro = {
    //       rate: er.rate,
    //       systemRate: er.rate,
    //       overrideRate: null,
    //       coin: er.coin,
    //       unit: er.coinUnit,
    //       fiat: er.fiat,
    //       canEdit: true,
    //       editing: false,
    //       source: 'Automatic'
    //     };
    //
    //     if(this.calculateFromValue && !isNaN(this.calculateFromValue)) {
    //       const amount = this.txn.amounts[0];
    //       const valRaw = amount.value;
    //       const val = math.bignumber(valRaw);
    //       const total = math.bignumber(this.calculateFromValue);
    //       const rate = total.div(val);
    //       ro.rate = rate.toFixed(2);
    //       ro.canEdit = false;
    //     }
    //
    //     rates.set(er.coin, ro);
    //   }
    //
    //   return rates;
    //
    //
    //
    //   // if(this.calculateFromValue) {
    //   //   if(isNaN(this.calculateFromValue)) {
    //   //     return new Map();
    //   //   }
    //   //
    //   //   if(this.txn.amounts.length > 1) {
    //   //     throw new Error("Can't calculate exchange rate on multiple values");
    //   //   } else {
    //   //     const amount = this.txn.amounts[0];
    //   //     const valRaw = amount.value;
    //   //     const val = math.bignumber(valRaw);
    //   //     const total = math.bignumber(this.calculateFromValue);
    //   //     const rate = total.div(val);
    //   //     const ro = {
    //   //       o: rate,
    //   //       coin: valRaw.coin,
    //   //       unit: getMainUnitForCoin(valRaw.coin),
    //   //       fiat: this.fiat,
    //   //       source: 'Invoice'
    //   //     };
    //   //     const rates = new Map();
    //   //     rates.set(amount.coin, ro);
    //   //     return rates;
    //   //   }
    //   // } else {
    //   //   const rates = new Map();
    //   //   for (const amount of this.txn.amounts) { // TODO this should be using the general exchange rates...
    //   //     let rate;
    //   //     if (amount.fiatValue.exchangeRate === null) {
    //   //       rate = 1;
    //   //     } else {
    //   //       rate = amount.fiatValue.exchangeRate.rate;
    //   //     }
    //   //     rates.set(amount.coin, rate);
    //   //   }
    //   //
    //   //   for (const fee of this.txn.fees) {
    //   //     rates.set(fee.coin, fee.fiatValue.exchangeRate.rate);
    //   //   }
    //   //
    //   //   return rates;
    //   // }
    // },
    feeDetails() {
      const f = this.txn.paidFees.map((m) => {
        /*
        cost: Float!
    exchangeRate: Float!
    currency: String!

         */
        let cbt = 'ExchangeRate';
        if (this.costBasisType === 'invoice') {
          cbt = 'Invoice';
        } else if (this.costBasisType === 'fixed') {
          cbt = 'Manual';
        }

        const rate = this.exchangeRateForCurrency(m.coin);
        const rateBN = this.bn(rate);
        return {
          amount: {
            value: m.value,
            coin: m.coin,
            unit: m.unit,
          },
          costBasis: {
            cost: this.calcTxnCostBasisFromAmount(m),
            exchangeRate: rateBN.toNumber(),
            currency: this.fiat,
            costBasisType: cbt,
          },
        };
      });
      return f;
    },
    fiat() {
      // const o = this.txn.fullAmountSet[0].fiatValue.currency;
      const cuu = this.$store.state.currentOrg.baseCurrency;
      // const cuu = getCurrencyFromEnum(bc);
      return cuu;
    },
    flatExchangeRates() {
      if (this.exchangeRates) {
        const entries = this.exchangeRates.values();
        const arr = Array.from(entries);
        return arr;
      } else {
        return [];
      }
    },
    // coin() {
    //   return this.amount.coin;
    // },
    changeableExchangeRate() {
      let cer = true;
      for (const a of this.amounts) {
        if (a?.fiatValue?.exchangeRate === null) {
          cer = false;
        }
      }
      return cer;
      // return this.amount.fiatValue.exchangeRate !== null;
    },
    exchangeRate() {
      if (this.amounts.length === 0) {
        return 0;
      } else if (this.amounts.length === 1) {
        return this.amounts[0].fiatValue.exchangeRate.rate;
      } else {
        throw new Error('Too many amounts for single exchange rate');
      }
    },
    // costBasis() {
    //   return this.amount.fiatValue.displayValue;
    // },
    // costBasisValue() {
    //   return this.amount.fiatValue.value;
    // },
    feeCostBasis() {
      if (this.txn.paidFees.length > 1) {
        throw new Error('Simple transactions can only have 1 fee');
      }

      if (this.txn.paidFees.length === 0) {
        return '0';
      }

      const fee = this.txn.paidFees[0];
      if (fee.coin === this.coin) {
        return fee.fiatValue.displayValue;
      } else {
        return '0';
      }
    },
    selectedExchangeRate() {
      if (this.costBasisType === 'exchangeRate') {
        return this.exchangeRate;
      } else if (this.costBasisType === 'invoice') {
        return this.invoiceExchangeRate;
      } else if (this.costBasisType === 'manual') {
        return this.manualExchangeRate;
      } else {
        throw new Error('Unimplented exchange rate type;');
      }
    },
    calcTotalCostBasis() {
      let total = math.bignumber(0);
      let totalPos = math.bignumber(0);
      let totalNeg = math.bignumber(0);
      for (const a of this.txn.amounts) {
        const val = this.bn(a.value);
        const rate = this.exchangeRateForCurrency(a.coin);
        const totalInt = total.add(math.bignumber(a.value).mul(rate)).abs();
        if (val.gte(0)) {
          totalPos = totalPos.plus(totalInt);
        } else {
          totalNeg = totalNeg.plus(totalInt);
        }
      }

      if (this.hasFee) {
        for (const f of this.txn.paidFees) {
          const rate = this.exchangeRateForCurrency(f.coin);
          totalNeg = totalNeg.add(math.bignumber(f.value).mul(rate)).abs();
        }
      }

      if (totalPos.gt(totalNeg)) {
        total = totalPos.sub(totalNeg);
      } else {
        total = totalNeg.sub(totalPos);
      }

      return Number(total.toFixed(2));
    },
    calcTotalCostBasisReal() {
      let total = math.bignumber(0);
      let totalPos = math.bignumber(0);
      let totalNeg = math.bignumber(0);
      for (const a of this.txn.amounts) {
        const val = this.bn(a.value);
        const rate = this.exchangeRateForCurrency(a.coin);
        const totalInt = total.add(math.bignumber(a.value).mul(rate)).abs();
        if (val.gte(0)) {
          totalPos = totalPos.plus(totalInt);
        } else {
          totalNeg = totalNeg.plus(totalInt);
        }
      }

      if (this.hasFee) {
        for (const f of this.txn.paidFees) {
          const rate = this.exchangeRateForCurrency(f.coin);
          totalNeg = totalNeg.add(math.bignumber(f.value).mul(rate)).abs();
        }
      }

      // calculate the total
      total = totalPos.sub(totalNeg);

      return Number(total.toFixed(2));
    },
    txnValue() {
      if (this.nonFeeAmounts.length === 1) {
        return this.nonFeeAmounts[0].value;
      } else {
        throw new Error('Unsupported txn value');
      }
    },
    // feeValue() {
    //   if (this.txn.fees.length > 1) {
    //     throw new Error("Simple transactions can only have 1 fee");
    //   }
    //
    //   if (this.txn.fees.length === 0) {
    //     return 0;
    //   }
    //
    //   const fee = this.txn.fees[0];
    //   if (fee.coin === this.coin) {
    //     return fee.value;
    //   } else {
    //     return 0;
    //   }
    // },
    // amountIncludingFees() {
    //   const amountWithFee = _.find(
    //     this.txn.amountsIncludingFees,
    //     m => m.coin === this.coin
    //   );
    //   if (amountWithFee === undefined) {
    //     throw new Error("Missing amounts with fees for amount");
    //   }
    //   return amountWithFee;
    // },
    // displayValue() {
    //   return this.amount.displayValue;
    // },
    // totalDisplayValue() {
    //   return this.amountIncludingFees.displayValue;
    // },
    // coinSymbol() {
    //   return getSymbolForCoin(this.amount.coin);
    // },
    filteredInvoices() {
      let items = [];
      if (this.txnType === 'receive') {
        const filtered = _.filter(this.invoices, (m) => m.type === 'Receiving');
        const sortedByName = _.sortBy(filtered, (m) => m.title);
        items = sortedByName;
      } else {
        const filtered = _.filter(this.invoices, (m) => m.type === 'Paying');
        const sortedByName = _.sortBy(filtered, (m) => m.title);
        items = sortedByName;
      }

      return items.map((m) => {
        m.desc = m.title + ' ' + m.contact.name + ' ($' + m.totalAmount + ')';
        return m;
      });
    },
    invoiceExchangeRate() {
      if (this.connectedInvoice && this.connectedInvoice.totalAmount) {
        console.log(this.connectedInvoice);
        const mainUnit = getMainUnitForCoin(this.amount.coin);
        const val = convertUnits(this.amount.coin, this.amount.unit, mainUnit, this.txnValue);
        const ret = this.connectedInvoice.totalAmount / val;
        return math.abs(ret.toFixed(2));
      } else {
        return '';
      }
    },
    invoiceCostBasis() {
      if (this.connectedInvoice) {
        return this.connectedInvoice.totalAmount;
      } else {
        return 0;
      }
    },
  },
  watch: {
    async dynamicSymbols(val) {
      for (const s of val) {
        console.log('looking up for val');
        await this.ensureDynamicExchangeRateExists(s);
      }
    },
  },
};
</script>
