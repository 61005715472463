<template>
  <div @mouseenter="hover = true" @mouseleave="hover = false" class="hover-div">
    <div style="display: flex">
      <span v-if="singleLink" class="txn-id" :title="id">
        <a :href="singleLink" target="_blank">{{ id }}</a>
      </span>
      <span v-else class="txn-id" :title="id">{{ id }}</span>
      <copy-to-clipboard :content="id" :iconSize="14" :tooltipMessage="$t('_copyIdToClipboard')" :visible="hover" />
    </div>
    <ul v-if="multiLink">
      <li v-for="item in multiLink" :key="item.link" style="text-overflow: ellipsis">
        <a :href="item.link" target="_blank">{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import CopyToClipboard from '../CopyToClipboard';

export default {
  props: ['id', 'amounts', 'lengthOfUrl', 'isBlockchain', 'viewLinks'],
  components: {
    CopyToClipboard,
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    singleLink() {
      if (this.viewLinks && this.viewLinks.length === 1) {
        return this.viewLinks[0].link;
      } else {
        return undefined;
      }
    },
    multiLink() {
      if (this.viewLinks && this.viewLinks.length > 1) {
        return this.viewLinks;
      } else {
        return undefined;
      }
    },
    url: function () {
      if (this.isBlockchain) {
        const coin = this.getCoin();
        if (coin === 'eth') {
          return 'https://etherscan.io/tx/' + this.id;
        } else if (coin === 'ZEN') {
          return 'https://explorer.zensystem.io/tx/' + this.id;
        } else if (coin === 'ZEC') {
          return 'https://explorer.zcha.in/transactions/' + this.id;
        } else {
          return 'https://blockchair.com/search?q=' + this.id;
        }
      }

      return undefined;
    },
  },
  methods: {
    getCoin() {
      if (this.amounts && this.amounts.length === 1 && this.amounts[0].coin !== 'USD') {
        return this.amounts[0].coin;
      } else {
        const eth = this.amounts.find((m) => m.coin === 'ETH');
        if (eth) {
          return 'ETH';
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
span {
  font-family: monospace;
}
a {
  color: #1696b7;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.hover-div {
  max-width: 120px;
}
.txn-id {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
