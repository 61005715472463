













































import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

@Component({
  apollo: {
    items: {
      watchLoading(isLoading) {
        this.$emit('isLoading', isLoading);
      },
      query: function () {
        return this.query;
      },
      loadingKey: 'isLoading',
      variables() {
        const { transactionFilter, orgId } = this.queryVars;

        //  TODO: don't love this, reason for this snippet, pivotDate is part of filter
        //  which is coming from parent prop, but it's set in this component
        //  need use an emitter and pivotDate coming from parent
        const transactionFilterCloned = cloneDeep(transactionFilter);

        const temp = {
          transactionFilter: transactionFilterCloned,
          orgId,
          limit: this.limit,
          paginationToken: this.paginationToken || undefined,
        };

        temp.transactionFilter.pivotDate = this.pivotDate;

        console.log('QueryVars', temp);
        return temp;
      },
      fetchPolicy: 'network-only',
      update(data) {
        this.$emit('items', data);
        return data;
      },
    },
  },
})
export default class Pagination extends BaseVue {
  @Prop({ required: true })
  public readonly query!: unknown;

  @Prop({ required: true })
  public readonly queryVars!: any;

  @Prop({ required: true })
  public readonly pagePivotFn!: (arg: unknown) => unknown;

  @Prop({ required: true })
  public readonly totalFn!: (arg: unknown) => unknown;

  @Prop({ required: true })
  public readonly itemsFn!: (arg: unknown) => unknown[];

  @Prop({ required: false })
  public readonly clickedOlder!: boolean;

  @Prop({ required: false })
  public readonly clickedNewer!: boolean;

  public paginationToken: string | null = null;
  public limit = 10;
  public items: any = [];
  public isLoading = 0;
  public fromShow = false;
  public pivotDate = this.formatDate(this.now());
  public erroredTransactions = [];

  public get hasOlder() {
    return this.items.transactions?.olderPageToken !== null;
  }

  public get hasNewer() {
    return this.items.transactions?.newerPageToken !== null;
  }

  public now() {
    return DateTime.local();
  }

  public formatDate(date: DateTime) {
    return date.toFormat('yyyy-LL-dd');
  }

  public async refetch() {
    return await this.$apollo.queries.items.refetch();
  }

  public itemArray(): unknown[] {
    return this.itemsFn(this.items);
  }

  public firstItem() {
    return this.itemArray()[0];
  }

  public lastItem() {
    return this.itemArray()[this.itemArray().length - 1];
  }

  public async onClickOlder() {
    this.paginationToken = this.items.transactions?.olderPageToken || null;
  }

  public async onClickNewer() {
    this.paginationToken = this.items.transactions?.newerPageToken || null;
  }

  public async onDateChanged() {
    this.fromShow = false;
    this.paginationToken = null;
  }

  public async onLimitChanged() {
    this.paginationToken = null;
  }

  @Watch('queryVars', {
    deep: true,
  })
  public onQueryVarsChange() {
    if (this.queryVars.transactionFilter?.pivotDate && this.pivotDate !== this.queryVars.transactionFilter.pivotDate) {
      this.pivotDate = this.queryVars.transactionFilter.pivotDate;
    }
    this.paginationToken = null;
    this.refetch();
  }

  @Watch('clickedOlder', { immediate: true })
  public externalClickedOlder() {
    this.onClickOlder();
  }

  @Watch('clickedNewer', { immediate: true })
  public externalClickedNewer() {
    this.onClickNewer();
  }

  @Watch('isLoading', { immediate: true })
  public onIsLoadingChange() {
    const newerVisible = this.hasNewer;
    const olderVisible = this.hasOlder;
    this.$emit('newerVisible', newerVisible);
    this.$emit('olderVisible', olderVisible);
  }

  @Watch('pivotDate', { immediate: true })
  public onPivotDateChange() {
    this.$emit('pivot-date-change', this.pivotDate);
  }
}
