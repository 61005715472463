








































import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';
import Chart from '@/components/Chart.vue';
import { PriceRecord } from '@/models/priceRecord';

import { convertUnits, getMainUnitForCoin, getSymbolForCoin, getSymbolForCurrency } from '../../utils/coinUtils';

@Component({
  name: 'GroupWalletItem',
  components: { Chart },
})
export default class GroupWalletItem extends BaseVue {
  @Prop({ required: true })
  priceId!: string;

  @Prop({ required: true })
  price!: string;

  @Prop({ required: true })
  priceFiat!: string;

  @Prop({ default: true })
  enabled!: boolean;

  priceData: PriceRecord | null = null;

  getSymbolForCurrency(coin: string) {
    return getSymbolForCurrency(coin);
  }

  loading = true;

  async loadPriceDetails() {
    const baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
    const orgId = this.$store.state.currentOrg.id;
    const url = baseUrl + `orgs/${orgId}/prices/${this.priceId}`;
    this.loading = true;
    const config: AxiosRequestConfig = {
      method: 'get',
      url,
      withCredentials: true,
    };

    const resp = await axios(config);
    console.log(resp);

    this.priceData = resp.data;

    this.loading = false;
  }
}
