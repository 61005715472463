


















































import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import type { Category, Contact, Transaction } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';

import type { CostBasisDTO } from '../types';

@Component
export default class SimpleCategorization extends BaseVue {
  @Prop({ required: true })
  readonly txn!: Transaction;

  @Prop({ required: true })
  readonly categories!: Category[];

  @Prop({ required: true })
  readonly contacts!: Contact[];

  @Prop({ required: true })
  readonly txnType!: string;

  @Prop({ required: true })
  readonly readonly!: boolean;

  @Prop({ required: true })
  readonly costBasis!: CostBasisDTO;

  description = '';
  category: Category | null = null;
  contact: Contact | null = null;

  mounted() {
    this.populateForm();
    this.updateTransactionData();
  }

  populateForm() {
    if (this.txn.accountingDetails && this.txn.accountingDetails.length === 1) {
      const [ad] = this.txn.accountingDetails;

      if (!ad) {
        return;
      }

      const { simple } = ad;

      if (!simple) {
        return;
      }

      this.description = simple.description;
      const foundCategory = this.categories.find((m) => m.id === simple.categoryId);
      this.category = foundCategory || null;

      const foundContact = this.contacts.find((m) => m.id === simple.contactId);
      this.contact = foundContact || null;
    }
  }

  updateTransactionData() {
    throw new Error('Simple Categorization save is deprecated.');
  }

  get filteredCategories() {
    return this.categories;
  }

  get filteredContacts() {
    return this.contacts;
  }

  @Watch('costBasis')
  watchCostBasis() {
    this.updateTransactionData();
  }
}
